import request from '@/utils/request';

/**
 * 获取历史记录
 * @param {string} start 开始时间
 * @param {string} end 结束时间
 * @returns
 */
export const getHistoryApi = (start, end) =>
  request({
    url: 'histories/v2',
    method: 'get',
    params: { start, end }
  });
