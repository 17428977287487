import { parseError } from './index.js';
import axios from 'axios';

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 60000 // request timeout
});

// request interceptor
service.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json; charset=utf-8';
    config.headers['Accept'] = '*/*';
    config.headers['Access-Control-Allow-Origin'] = '*';
    return config;
  },
  error => {
    console.error(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  response => {
    return response.data;
  },
  async error => {
    console.error(error); // for debug
    if (!error.response) return Promise.reject(error.message);

    return Promise.reject(parseError(error.response.data.message));
  }
);

export default service;
