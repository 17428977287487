<template>
  <div class="home-container">
    <header-bar></header-bar>
    <map-container></map-container>
  </div>
</template>

<script>
import HeaderBar from './HeaderBar.vue';
import MapContainer from './MapContainer.vue';
export default {
  components: { HeaderBar, MapContainer },
  data() {
    return {};
  }
};
</script>

<style lang="scss">
.home-container {
  // width: 100vw;
  // height: 100vh;
}
</style>
