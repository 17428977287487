import Vue from 'vue';
import App from './App.vue';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'; //当不使用cdn的时候，将这行开启！

// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view';

Vue.use(dataV);

Vue.use(ElementUI);

Vue.config.productionTip = false;

new Vue({
  render: h => h(App)
}).$mount('#app');
