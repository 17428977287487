<template>
  <div class="data-table">
    <div class="search-form">
      <el-form inline>
        <el-form-item>
          <el-date-picker type="datetimerange"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            v-model="range"
            format="yyyy-MM-dd HH:mm"
            @change="getDataList"></el-date-picker>
        </el-form-item>
        <el-button @click="getDataList"
          :loading="loading">刷新</el-button>
        <el-dropdown>
          <el-button type="primary"
            plain
            style="margin-left: 10px">地图展示</el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="setMapData('SO2')">
              <el-button type="text">SO2</el-button>
            </el-dropdown-item>
            <el-dropdown-item @click.native="setMapData('NOx')">
              <el-button type="text">NO</el-button>
            </el-dropdown-item>
            <el-dropdown-item @click.native="setMapData('PM')">
              <el-button type="text">PM</el-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- <el-button @click="setMapData">地图展示</el-button> -->
      </el-form>
    </div>
    <el-table :data="dataList"
      size="small">
      <el-table-column type="index"></el-table-column>
      <el-table-column prop="longitude"
        label="经度"><template #default="scope">{{ scope.row.longitude.toFixed(7) }}</template></el-table-column>
      <el-table-column prop="latitude"
        label="纬度">
        <template #default="scope">{{ scope.row.latitude.toFixed(7) }}</template>
      </el-table-column>
      <el-table-column prop="NOx"
        label="NOx浓度"></el-table-column>
      <el-table-column prop="SO2"
        label="SO2浓度"></el-table-column>
      <el-table-column prop="PM"
        label="PM浓度"></el-table-column>
      <el-table-column prop="createdAt"
        label="时间"
        width="140"></el-table-column>
    </el-table>
    <el-pagination class="pagination"
      background
      :current-page.sync="page.currentPage"
      :page-size.sync="page.pageSize"
      :page-sizes="[5, 10, 20, 50, 100]"
      layout="total, prev, pager, next, jumper"
      :total="data.length"></el-pagination>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { Message } from 'element-ui';
import { getHistoryApi } from '@/apis/history';
import { parseError } from '@/utils';
export default {
  data() {
    return {
      data: [],
      page: {
        currentPage: 1,
        pageSize: 10
      },
      range: [new Date(new Date().toLocaleDateString()), new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000)],
      loading: false
    };
  },
  computed: {
    dataList() {
      return this.data
        .filter((_, index) => index < this.page.currentPage * this.page.pageSize && index >= (this.page.currentPage - 1) * this.page.pageSize)
        .map((item, index) => ({
          longitude: item.longitude,
          latitude: item.latitude,
          SO2: item.so2,
          NOx: item.nox,
          PM: item.pm,
          createdAt: dayjs(item.created_at).format('YYYY-MM-DD HH:mm:ss')
        }));
    }
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    async getDataList() {
      try {
        this.loading = true;
        this.data = (await getHistoryApi(this.range[0], this.range[1])).filter((item, index) => !(index % 5));
      } catch (error) {
        console.error(error);
        Message.error(parseError(error));
      }
      this.loading = false;
    },
    // pageSizeChangeHandler() {
    //   this.getDataList()
    // }
    setMapData(type) {
      this.$emit('set-data', this.data, type);
    }
  }
};
</script>

<style lang="scss" scoped>
.data-table {
  padding: 20px;
  // background: rgba(10, 46, 133, 0.5);
  overflow: hidden;

  ::v-deep .el-table {
    background: transparent;
    tr,
    th {
      background: transparent;
      color: #fff;
    }
    .el-table__row:hover {
      td {
        background-color: #f5f7fa40;
      }
    }
  }
  .pagination {
    margin-top: 20px;
    color: #fff;
    ::v-deep .el-pagination__total {
      color: #fff;
    }
    ::v-deep .el-pagination__jump {
      color: #fff;
    }
    ::v-deep .el-pagination__total {
      color: #fff;
    }
  }
  ::v-deep .el-pager {
    .number {
      background: transparent;
      color: #fff;
    }
  }
  ::v-deep .btn-prev {
    background: transparent !important;
    color: #fff !important;
  }
  ::v-deep .btn-next {
    background: transparent !important;
    color: #fff !important;
  }
  ::v-deep .el-input__inner {
    background: transparent;
    color: #fff;
  }
  ::v-deep .el-range-input {
    background: transparent;
    color: #fff;
  }
}
</style>
