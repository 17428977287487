<template>
  <div id="app">
    <!-- <header-component></header-component>
    <map-demo></map-demo> -->
    <home></home>
  </div>
</template>

<script>
import MapDemo from './components/mapDemo1.vue';
import HeaderComponent from './components/HeaderBar.vue';
import echarts from 'echarts';
import Home from '@/components/Home.vue';

export default {
  name: 'App',
  components: {
    // MapDemo,
    // HeaderComponent
    Home
  },
  data() {
    return {
      chart: null
    };
  },
  mounted() {}
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}

body {
  margin: 0;
  padding: 0;
}

.chart-box {
  position: fixed;
  width: 400px;
  height: 240px;
  z-index: 1100;
}

.chart {
  width: 400px;
  height: 240px;
}
</style>
