import AMap from 'AMap';
// import coordtransform from 'coordtransform';
const coordtransform = require('coordtransform');

// 错误格式化
export const parseError = error => {
  return typeof error === 'string' || error instanceof String ? `${error}` : JSON.stringify(error) === '{}' ? error.toString() : JSON.stringify(error);
};

// export const parseLnglat = (lng, lat, service) => {
//   return new Promise((resolve, reject) => {
//     AMap.convertFrom([lng, lat], 'gps', (status, result) => {
//       if (status === 'complete') {
//         resolve([result.locations[0].lng, result.locations[0].lat]);
//       } else {
//         reject('转换错误');
//       }
//     });
//   });
// };

export const parseLnglat = (lng, lat) => {
  // return new Promise((resolve, reject) => {
  //   resolve(coordtransform.wgs84togcj02(lng, lat));
  // });
  return coordtransform.wgs84togcj02(lng, lat);
};

export const toWgs84 = (lng, lat) => {
  return coordtransform.gcj02towgs84(lng, lat);
};
