<template>
  <div class="com-container" :style="{ top: top, left: left, right: right, bottom: bottom, height: height, width: width }">
    <slot></slot>
    <div class="container-footer"></div>
  </div>
</template>

<script>
export default {
  props: {
    top: String,
    left: String,
    right: String,
    bottom: String,
    height: String,
    width: {
      type: String,
      default: '400px'
    }
  }
};
</script>

<style lang="scss" scoped>
.com-container {
  border: 1px solid #294779;
  position: fixed;
  width: 400px;
  height: 240px;
  background: rgba(10, 46, 133, 0.5);
  z-index: 1100;
  .total-title {
    position: absolute;
    top: 4%;
    left: 4%;
    font-size: 12px;
  }
  .com-chart {
  }
  .bottom {
    font-size: 12px;
    position: absolute;
    bottom: 10%;
    right: 10%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .rect {
      content: '';
      background: #21dfb3;
      width: 14px;
      height: 14px;
      margin-right: 10px;
    }
    .text {
    }
  }
  .container-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 26px;
      height: 26px;
      border-left: 2px solid #69e0f0;
      border-bottom: 2px solid #69e0f0;
      content: '';
    }
    &::after {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 26px;
      height: 26px;
      border-right: 2px solid #69e0f0;
      border-bottom: 2px solid #69e0f0;
      content: '';
    }
  }
}
.com-container::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 26px;
  height: 26px;
  border-left: 2px solid #69e0f0;
  border-top: 2px solid #69e0f0;
  content: '';
}
.com-container::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 26px;
  height: 26px;
  border-right: 2px solid #69e0f0;
  border-top: 2px solid #69e0f0;
  content: '';
}
</style>
