<template>
  <el-dialog :visible.sync="dialogVisible"
    title="设置参照点"
    :close-on-click-modal="false"
    custom-class="config-dialog">
    <el-form :model="configForm"
      class="config-form"
      label-width="40px">
      <el-form-item label="参照点1"
        label-width="80px">
        <div class="config-form_row">
          <el-form-item label="经度"
            prop="point1.longitude">
            <el-input v-model="configForm.point1.longitude"
              class="config-form_input"
              placeholder="请输入参照点1经度"
              type="number"></el-input>
          </el-form-item>
          <el-form-item label="纬度"
            prop="point1.longitude">
            <el-input v-model="configForm.point1.latitude"
              placeholder="请输入参照点1纬度"
              type="number"></el-input>
          </el-form-item>
        </div>
        <div class="config-form_row">
          <el-form-item label="x"
            prop="point1.longitude">
            <el-input v-model="configForm.point1.x"
              placeholder="请输入参照点1x值"
              type="number"></el-input>
          </el-form-item>
          <el-form-item label="y"
            prop="point1.longitude">
            <el-input v-model="configForm.point1.y"
              placeholder="请输入参照点1y值"
              type="number"></el-input>
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="参照点2"
        label-width="80px">
        <div class="config-form_row">
          <el-form-item label="经度"
            prop="point2.longitude">
            <el-input v-model="configForm.point2.longitude"
              placeholder="请输入参照点2经度"
              type="number"></el-input>
          </el-form-item>
          <el-form-item label="纬度"
            prop="point2.longitude">
            <el-input v-model="configForm.point2.latitude"
              placeholder="请输入参照点2纬度"
              type="number"></el-input>
          </el-form-item>
        </div>
        <div class="config-form_row">
          <el-form-item label="x"
            prop="point2.longitude">
            <el-input v-model="configForm.point2.x"
              placeholder="请输入参照点2x值"
              type="number"></el-input>
          </el-form-item>
          <el-form-item label="y"
            prop="point2.longitude">
            <el-input v-model="configForm.point2.y"
              placeholder="请输入参照点2y值"
              type="number"></el-input>
          </el-form-item>
        </div>
      </el-form-item>
    </el-form>
    <template slot="footer">
      <el-button @click="dialogVisible= false">取消</el-button>
      <el-button type="primary"
        @click="confirm">确认</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { setReferencePointApi } from '@/apis/detect';
import { Message } from 'element-ui';
export default {
  data() {
    return {
      dialogVisible: false,
      configForm: {
        point1: {
          latitude: '',
          longitude: '',
          x: '',
          y: ''
        },
        point2: {
          latitude: '',
          longitude: '',
          x: '',
          y: ''
        }
      }
    };
  },
  methods: {
    showDialog() {
      this.dialogVisible = true;
    },
    confirm() {
      setReferencePointApi(this.configForm.point1, this.configForm.point2)
        .then(res => {
          this.dialogVisible = false;
        })
        .catch(error => Message.error(error));
    }
  }
};
</script>

<style lang="scss" scoped>
.config-form {
  &_row {
    ::v-deep .el-form-item {
      margin-bottom: 20px;
    }
  }
}
::v-deep .el-input {
  width: 300px;
}
</style>

<style lang="scss">
.config-dialog {
  width: 500px !important;
}
</style>
