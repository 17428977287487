<template>
  <div class="map-container"
    v-loading="loading"
    element-loading-text="数据采集中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="map-container_content"
      id="local-map"></div>

    <InfoBox :sensorHover="sensorHover" />

    <div class="button-group">
      <div class="button-group_content"
        v-if="!trackMode">
        <el-button type="primary"
          plain
          @click="showReferenceDialog">设置参照点</el-button>
        <el-dropdown>
          <el-button type="primary"
            plain
            style="margin-left: 10px">监测点1</el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="getPointData(1)">
              <el-button type="text">采集数据</el-button>
            </el-dropdown-item>
            <el-dropdown-item @click.native="setPointData(1)">
              <el-button type="text">当前数据</el-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown>
          <el-button type="primary"
            plain
            style="margin-left: 10px">监测点2</el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="getPointData(2)">
              <el-button type="text">采集数据</el-button>
            </el-dropdown-item>
            <el-dropdown-item @click.native="setPointData(2)">
              <el-button type="text">当前数据</el-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown>
          <el-button type="primary"
            plain
            style="margin-left: 10px; margin-right: 10px">监测点3</el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="getPointData(3)">
              <el-button type="text">采集数据</el-button>
            </el-dropdown-item>
            <el-dropdown-item @click.native="setPointData(3)">
              <el-button type="text">当前数据</el-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown>
          <el-button type="primary"
            plain
            style=" margin-right: 10px">溯源</el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <el-button type="text">重新溯源</el-button>
            </el-dropdown-item>
            <el-dropdown-item @click.native="getSource()">
              <el-button type="text">当前污染源</el-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <el-button type="primary"
          plain
          @click="startTrack">行车记录</el-button>
        <el-dropdown>
          <el-button type="primary"
            plain
            style="margin-left: 10px">{{ historyMode }}</el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="getOneHourData()">
              <el-button type="text">近一小时</el-button>
            </el-dropdown-item>
            <el-dropdown-item @click.native="getOneDayData()">
              <el-button type="text">近一天</el-button>
            </el-dropdown-item>
            <el-dropdown-item @click.native="showDataTable()">
              <el-button type="text">更多</el-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="button-group_track"
        v-else>
        <el-button type="primary"
          plain
          @click="stopTrack">退出轨迹</el-button>
      </div>
    </div>

    <input class="search-box"
      id="search"
      placeholder="请输入地点" />

    <transition name="slide-fade">
      <div class="transition-box"
        v-show="tableShow">
        <div class="transition-box_table">
          <dv-border-box-12 ref="tableBorder"
            backgroundColor="#0a2e85c0">
            <DataTable ref="dataTable"
              @set-data="setMapData" />
          </dv-border-box-12>
        </div>
      </div>
    </transition>

    <transition name="slide-fade">
      <div class="transition-box"
        v-show="sensorSelected">
        <div class="transition-box_chart"
          :style="{height:sensorSelected&&sensorSelected.SO2&&sensorSelected.NOx&&sensorSelected.PM?'60vh':''}">
          <dv-border-box-12 ref="chartBorder"
            backgroundColor="#0a2e85c0"
            type="NOx">
            <DataChart :sensorSelected="sensorSelected" />
          </dv-border-box-12>
        </div>
        <!-- <div class="transition-box_chart">
          <dv-border-box-12 ref="chartBorder1" backgroundColor="#0a2e85c0">
            <DataChart :sensorSelected="sensorSelected" type="SO2" />
          </dv-border-box-12>
        </div>
        <div class="transition-box_chart">
          <dv-border-box-12 ref="chartBorder2" backgroundColor="#0a2e85c0">
            <DataChart :sensorSelected="sensorSelected" type="PM" />
          </dv-border-box-12>
        </div> -->
        <div class="transition-box_data">
          <dv-border-box-12 ref="boxBorder"
            backgroundColor="#0a2e85c0">
            <DataBox :sensorSelected="sensorSelected" />
          </dv-border-box-12>
        </div>
      </div>
    </transition>

    <!-- <transition name="slide-fade-left">
      <div class="transition-box" v-show="sensorSelected">
        <div class="transition-box_data">
          <dv-border-box-12 ref="boxBorder" backgroundColor="#0a2e85c0">
            <DataBox :sensorSelected="sensorSelected" />
          </dv-border-box-12>
        </div>
      </div>
    </transition> -->
    <ReferenceConfig ref="referenceConfig" />
  </div>
</template>

<script>
import AMap from 'AMap';
import Loca from 'Loca';
import DataChart from './DataChart.vue';
import DataBox from './DataBox.vue';
import DataTable from './DataTable.vue';
import InfoBox from './InfoBox.vue';
import ReferenceConfig from './ReferenceConfig.vue';
import { parseError, parseLnglat, toWgs84 } from '@/utils';
import { io } from 'socket.io-client';
import { getPointRecordApi } from '@/apis/record';
import { setPointDataApi, traceApi } from '@/apis/detect';
import { Message } from 'element-ui';
import { getHistoryApi } from '@/apis/history';
import { GasColor, GasLngOffset } from '@/constants/common';
import dayjs from 'dayjs';

export default {
  components: { DataChart, DataBox, DataTable, InfoBox, ReferenceConfig },
  data() {
    return {
      // 地图元素
      map: null,
      loca: null,
      car: null,
      geocoder: null,
      NOxLayer: null,
      SO2Layer: null,
      PMLayer: null,
      NOxLayerList: [],
      SO2LayerList: [],
      PMLayerList: [],
      historyLayer: null,
      breathRed: null,

      historyMode: '历史记录',
      trackMode: false,
      sensorHover: null,
      sensorSelected: null,
      tableShow: false,
      loading: false,
      SO2List: [],
      NOList: [],
      NOxList: [],
      PMList: [],
      interval: null,
      trackList: [],

      // socket
      SO2Socket: null,
      NOxSocket: null,
      gpsSocket: null,
      PMSocket: null
    };
  },
  watch: {
    tableShow(value) {
      if (value) {
        const tableBorder = this.$refs.tableBorder;
        if (!tableBorder.width || !tableBorder.height) {
          tableBorder.initWH();
        }
      }
    },
    sensorSelected: {
      handler(value) {
        if (value) {
          this.$nextTick(() => {
            const chartBorder = this.$refs.chartBorder;
            // if (!chartBorder.width || !chartBorder.height) {
            chartBorder.initWH();
            // }
            // const chartBorder1 = this.$refs.chartBorder1;
            // if (!chartBorder1.width || !chartBorder1.height) {
            //   chartBorder1.initWH();
            // }
          });

          // const chartBorder2 = this.$refs.chartBorder2;
          // if (!chartBorder2.width || !chartBorder2.height) {
          //   chartBorder2.initWH();
          // }
          const boxBorder = this.$refs.boxBorder;
          if (!boxBorder.width || !boxBorder.height) {
            boxBorder.initWH();
          }
        }
      },
      deep: true
    }
  },
  mounted() {
    this.initMap();
    this.initLoca();
    this.initCar();
    this.initSocket();
  },
  methods: {
    initMap() {
      // 地图主体
      this.map = new AMap.Map('local-map', {
        resizeEnable: true,
        zoom: 18, //级别
        viewMode: '3D', //使用3D视图
        // center: [108.7570724, 34.23283386],
        center: [121.528068, 38.881448],
        // layers: [new AMap.TileLayer.Satellite()],
        mapStyle: 'amap://styles/8ae6250b21f82d50499840d20be9e518',
        pitch: 45,
        features: ['bg', 'road', 'building', 'point'],
        pitchEnable: true,
        showBuildingBlock: true
      });

      // 引入插件
      // 地图缩放
      // AMap.plugin(['AMap.ToolBar'], () => {
      //   this.map.addControl(new AMap.ToolBar());
      // });

      // 移动动画
      AMap.plugin('AMap.MoveAnimation', item => {});

      // 引入区域编码
      AMap.plugin('AMap.Geocoder', () => {
        this.geocoder = new AMap.Geocoder({
          city: '全国'
        });
      });

      // 增加地点搜索
      AMap.plugin('AMap.AutoComplete', () => {
        // 实例化AutoComplete
        const autoOptions = {
          // input 为绑定输入提示功能的input的DOM ID
          input: 'search'
        };
        const autoComplete = new AMap.AutoComplete(autoOptions);
        // 无需再手动执行search方法，autoComplete会根据传入input对应的DOM动态触发search
        autoComplete.on('select', e => {
          this.map.setCenter(new AMap.LngLat(e.poi.location.lng, e.poi.location.lat));
        });
      });

      this.map.on('click', e => {
        if (!this.NOxLayer && !this.SO2Layer && !this.PMLayer) {
          this.sensorSelected = null;
          this.tableShow = false;
          return;
        }
        const pixel = e.pixel.toArray();
        const feature = this.NOxLayer?.queryFeature(pixel) || this.SO2Layer?.queryFeature(pixel) || this.PMLayer?.queryFeature(pixel);
        if (feature) {
          let type = '';
          const { lng, lat } = feature.properties.data;
          this.sensorSelected = { lng, lat };
          this.sensorSelected[feature.properties.type] = feature.properties.data[feature.properties.type];
          this.tableShow = false;
        } else {
          this.sensorSelected = null;
          this.tableShow = false;
        }
      });
    },
    initCar() {
      this.car = new AMap.Marker({
        map: this.map,
        position: [121.528092, 38.880949],
        icon: 'https://a.amap.com/jsapi_demos/static/demo-center-v2/car.png',
        offset: new AMap.Pixel(-26, -13),
        visible: true,
        autoRotation: true
        // angle: 90
      });
    },
    initLoca() {
      this.loca = new Loca.Container({
        map: this.map
      });
      this.loca.ambLight = {
        intensity: 0.7,
        color: '#7b7bff'
      };
      this.loca.dirLight = {
        intensity: 0.8,
        color: '#fff',
        target: [0, 0, 0],
        position: [0, -1, 1]
      };
      this.loca.pointLight = {
        color: 'rgb(240,88,25)',
        position: [112.028276, 31.58538, 2000000],
        intensity: 3,
        // 距离表示从光源到光照强度为 0 的位置，0 就是光不会消失。
        distance: 5000000
      };
      // NOx柱图层
      // this.NOxLayer = new Loca.PrismLayer({
      //   // loca: this.loca,
      //   zIndex: 1000,
      //   opacity: 1,
      //   visible: false,
      //   hasSide: true
      // });
      // this.NOxLayer.setStyle({
      //   sideTopColor: '#0091ff',
      //   topColor: '#0091ff',
      //   sideBottomColor: '#0091ff',
      //   height: (index, item) => {
      //     return item.properties.NOx / 100;
      //   },
      //   unit: 'meter',
      //   radius: 2,
      //   sideNumber: 32
      // });
      // this.loca.add(this.NOxLayer);

      // // SO2柱图层
      // this.SO2Layer = new Loca.PrismLayer({
      //   // loca: this.loca,
      //   zIndex: 1000,
      //   opacity: 1,
      //   visible: false,
      //   hasSide: true
      // });
      // this.SO2Layer.setStyle({
      //   sideTopColor: '#ff8500',
      //   topColor: '#ff8500',
      //   sideBottomColor: '#ff8500',
      //   height: (index, item) => {
      //     return item.properties.SO2;
      //   },
      //   unit: 'meter',
      //   radius: 2,
      //   sideNumber: 32
      // });

      // this.loca.add(this.SO2Layer);
      // loca 调试用
      // this.dat = new Loca.Dat();
    },
    getPointData(index) {
      clearInterval(this.interval);
      this.interval = null;
      this.car.hide();
      this.loading = true;
      this.trackList = [];
      this.SO2List = [];
      this.NOList = [];
      this.NOxList = [];
      this.PMList = [];
      this.NOxLayerList.forEach(item => {
        this.loca.remove(item);
      });
      this.SO2LayerList.forEach(item => this.loca.remove(item));
      this.PMList.forEach(item => this.loca.remove(item));
      this.NOxLayerList = [];
      this.SO2LayerList = [];
      setTimeout(async () => {
        try {
          if (!this.NOxList.length || !this.SO2List.length || !this.trackList.length) {
            Message.error('请检查设备,缺少数据');
            return;
          }
          const SO2 = this.SO2List.reduce((prev, cur) => prev + cur, 0) / this.SO2List.length;
          const NOx = this.NOxList.reduce((prev, cur) => prev + cur, 0) / this.NOxList.length;
          const lng = this.trackList.map(item => item[0]).reduce((prev, cur) => prev + cur, 0) / this.trackList.length;
          const lat = this.trackList.map(item => item[1]).reduce((prev, cur) => prev + cur, 0) / this.trackList.length;
          const NO = this.NOList.reduce((prev, cur) => prev + cur, 0) / this.NOList.length;
          const PM = this.PMList.reduce((prev, cur) => prev + cur, 0) / this.PMList.length;
          const [longitude, latitude] = parseLnglat(lng, lat);
          await setPointDataApi(index, lat, lng, SO2, NO, NOx);
          this.addPointPrism(longitude, latitude, NOx, SO2, PM);
          this.setChart(lng, lat, NOx, SO2, PM);
          Message.success('数据上成功');
        } catch (error) {
          Message.error(parseError(error));
        }
        this.loading = false;
        // this.startInterval();
      }, 10000);
    },
    async trace() {
      try {
        await traceApi();
        Message.success('溯源成功');
      } catch (error) {
        Message.error(parseError(error));
      }
    },
    startTrack() {
      if (this.interval) return;
      this.car.show();
      this.trackList = [];
      this.SO2List = [];
      this.NOxList = [];
      if (this.SO2Layer) {
        this.loca.remove(this.SO2Layer);
        this.SO2Layer = null;
      }
      if (this.NOxLayer) {
        this.loca.remove(this.NOxLayer);
        this.NOxLayer = null;
      }
      if (this.PMLayer) {
        this.loca.remove(this.PMLayer);
        this.PMLayer = null;
      }
      this.initGpsSocket();
      this.initNOxSocket();
      this.initSO2Socket();
      this.startInterval();
    },
    stopTrack() {},
    async showDataTable() {
      this.tableShow = !this.tableShow;
      this.sensorSelected = null;
    },
    async getOneHourData() {
      try {
        clearInterval(this.interval);
        this.interval = null;
        this.car.hide();
        this.trackList = [];
        this.SO2List = [];
        this.NOList = [];
        this.NOxList = [];
        this.PMList = [];
        this.NOxLayerList.forEach(item => {
          this.loca.remove(item);
        });
        this.SO2LayerList.forEach(item => this.loca.remove(item));
        this.PMLayerList.forEach(item => this.loca.remove(item));
        this.NOxLayerList = [];
        this.SO2LayerList = [];
        this.PMLayerList = [];
        const dataList = await getHistoryApi(new Date(new Date().getTime() - 60 * 60 * 1000), new Date());
        this.addListPrism(dataList);
      } catch (error) {
        Message.error(parseError(error));
      }
    },
    async getOneDayData() {
      try {
        clearInterval(this.interval);
        this.interval = null;
        this.car.hide();
        this.trackList = [];
        this.SO2List = [];
        this.NOList = [];
        this.NOxList = [];
        this.PMList = [];
        this.NOxLayerList.forEach(item => {
          this.loca.remove(item);
        });
        this.SO2LayerList.forEach(item => this.loca.remove(item));
        this.PMLayerList.forEach(item => this.loca.remove(item));
        this.NOxLayerList = [];
        this.SO2LayerList = [];
        this.PMLayerList = [];
        const dataList = (
          await getHistoryApi(new Date(new Date().toLocaleDateString()), new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000))
        ).filter((item, index) => !(index % 5));
        this.addListPrism(dataList);
      } catch (error) {
        Message.error(parseError(error));
      }
    },
    showReferenceDialog() {
      this.$refs.referenceConfig.showDialog();
    },
    initSocket() {
      this.initGpsSocket();
      this.initNOxSocket();
      this.initSO2Socket();
      this.initPMSocket();
      this.startInterval();
    },
    initGpsSocket() {
      this.gpsSocket = io(process.env.VUE_APP_BASE_API + '/gps');
      this.gpsSocket.on('connect', _ => {
        this.gpsSocket.emit('joinRoom', res => {
          if (!res) Message.error('节点不存在');
        });
      });
      this.gpsSocket.on('gps', this.gpsHandler);
    },
    async gpsHandler(gpsLongitude, gpsLatitude) {
      let longitude, latitude;
      // [longitude, latitude] = await parseLnglat(gpsLongitude, gpsLatitude);
      [longitude, latitude] = parseLnglat(gpsLongitude, gpsLatitude);
      if (!this.car.getOptions().visible) {
        this.car.setPosition([longitude, latitude]);
        this.car.show();
        this.map.setCenter([longitude, latitude]);
      }
      this.car.moveTo(new AMap.LngLat(longitude, latitude), {
        duration: 1000
      });
      this.trackList.push([gpsLongitude, gpsLatitude]);
      // if (this.trackList.length >= 10) {
      //   const polyline = new AMap.Polyline({
      //     map: this.map,
      //     path: this.trackList,
      //     showDir: false,
      //     strokeColor: '#28F', //线颜色
      //     strokeOpacity: 0, //线透明度
      //     strokeWeight: 6, //线宽
      //     strokeStyle: 'solid' //线样式
      //   });
      //   const passedPolyline = new AMap.Polyline({
      //     map: this.map,
      //     // path: lineArr,
      //     strokeColor: '#AF5', //线颜色
      //     // strokeOpacity: 1,     //线透明度
      //     strokeWeight: 6 //线宽
      //     // strokeStyle: "solid"  //线样式
      //   });
      // this.car.on('moving', function (e) {
      //   passedPolyline.setPath(e.passedPath);
      // });
      // this.map.setFitView();

      //   this.car.moveAlong(
      //     this.trackList.map(item => parseLnglat(item[0], item[1])),
      //     {
      //       duration: 1000,
      //       autoRotation: true
      //     }
      //   );
      //   this.trackList = [this.trackList[this.trackList.length - 1]];
      //   this.trackList.push([parseFloat(longitude), parseFloat(latitude)]);
      // } else this.trackList.push([parseFloat(longitude), parseFloat(latitude)]);
    },
    initNOxSocket() {
      this.NOxSocket = io(process.env.VUE_APP_BASE_API + '/no');
      this.NOxSocket.on('connect', _ => {
        this.NOxSocket.emit('joinRoom', res => {
          if (!res) Message.error('节点不存在');
        });
      });
      this.NOxSocket.on('no', this.NOxHandler);
    },
    NOxHandler(NO, NO2, NOx) {
      this.NOxList.push(parseFloat(NOx));
      this.NOList.push(parseFloat(NO));
    },
    initSO2Socket() {
      this.SO2Socket = io(process.env.VUE_APP_BASE_API + '/so2');
      this.SO2Socket.on('connect', _ => {
        this.SO2Socket.emit('joinRoom', res => {
          if (!res) Message.error('节点不存在');
        });
      });
      this.SO2Socket.on('so2', this.SO2Handler);
    },
    SO2Handler(SO2) {
      this.SO2List.push(parseFloat(SO2));
    },
    initPMSocket() {
      this.PMSocket = io(process.env.VUE_APP_BASE_API + '/pm');
      this.PMSocket.on('connect', _ => {
        this.PMSocket.emit('joinRoom', res => {
          if (!res) Message.error('节点不存在');
        });
      });
      this.PMSocket.on('pm', this.PMHandler);
    },
    PMHandler(PM) {
      this.PMList.push(parseFloat(PM));
    },
    releaseSocket() {
      if (this.gpsSocket) {
        this.gpsSocket.on('disconnect', _ => {});
        this.gpsSocket.close();
        this.gpsSocket = undefined;
      }
      if (this.NOxSocket) {
        this.NOxSocket.on('disconnect', _ => {});
        this.NOxSocket.close();
        this.NOxSocket = undefined;
      }
      if (this.SO2Socket) {
        this.SO2Socket.on('disconnect', _ => {});
        this.SO2Socket.close();
        this.SO2Socket = undefined;
      }
      if (this.PMSocket) {
        this.PMSocket.on('disconnect', _ => {});
        this.PMSocket.close();
        this.PMSocket = undefined;
      }
    },
    startInterval() {
      this.interval = setInterval(() => {
        if (!this.NOxList.length || !this.SO2List.length || !this.trackList.length || !this.PMList.length) {
          clearInterval(this.interval);
          this.interval = null;
          Message.error('请检查设备,缺少数据');
          return;
        }
        if (this.NOxLayerList.length && this.SO2LayerList.length && this.PMLayerList.length) {
          this.NOxLayerList[0].hide(500);
          this.SO2LayerList[0].hide(500);
          this.PMLayerList[0].hide(500);
          this.loca.remove(this.NOxLayerList[0]);
          this.loca.remove(this.SO2LayerList[0]);
          this.loca.remove(this.PMLayerList[0]);
          this.NOxLayerList.shift();
          this.SO2LayerList.shift();
          this.PMLayerList.shift();
        }
        const NOx = this.NOxList[this.NOxList.length - 1] || 0;
        const SO2 = this.SO2List[this.SO2List.length - 1] || 0;
        const PM = this.PMList[this.PMList.length - 1] || 0;
        const [lng, lat] = parseLnglat(...this.trackList[this.trackList.length - 1]);

        // NOx
        const NOxSource = new Loca.GeoJSONSource({
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [lng + GasLngOffset, lat]
                },
                properties: {
                  value: NOx / 100,
                  data: { lng, lat, NOx, SO2, PM }
                }
              }
            ]
          }
        });
        const NOxLayer = new Loca.PrismLayer({
          zIndex: 1000,
          opacity: 1,
          visible: false,
          hasSide: true
        });
        NOxLayer.setSource(NOxSource);
        NOxLayer.setStyle({
          sideTopColor: GasColor.NOx,
          topColor: GasColor.NOx,
          sideBottomColor: GasColor.NOx,
          height: (index, item) => {
            return item.properties.value;
          },
          unit: 'meter',
          radius: 2,
          sideNumber: 32
        });
        this.loca.add(NOxLayer);
        this.NOxLayerList.push(NOxLayer);

        NOxLayer.addAnimate({
          key: 'height',
          value: [0, 1],
          duration: 500,
          easing: 'Linear'
        });
        NOxLayer.show(500);

        // SO2
        const SO2Source = new Loca.GeoJSONSource({
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [lng - GasLngOffset, lat]
                },
                properties: {
                  value: SO2 * 5,
                  data: { lng, lat, NOx, SO2, PM }
                }
              }
            ]
          }
        });
        const SO2Layer = new Loca.PrismLayer({
          zIndex: 1000,
          opacity: 1,
          visible: false,
          hasSide: true
        });
        SO2Layer.setSource(SO2Source);
        SO2Layer.setStyle({
          sideTopColor: (index, item) => {
            return item.properties.value > 400 ? GasColor.Danger : GasColor.SO2;
          },
          topColor: (index, item) => {
            return item.properties.value > 400 ? GasColor.Danger : GasColor.SO2;
          },
          sideBottomColor: GasColor.SO2,
          height: (index, item) => {
            return item.properties.value;
          },
          unit: 'meter',
          radius: 2,
          sideNumber: 32
        });

        this.loca.add(SO2Layer);
        this.SO2LayerList.push(SO2Layer);

        SO2Layer.addAnimate({
          key: 'height',
          value: [0, 1],
          duration: 500,
          easing: 'Linear'
        });
        SO2Layer.show(500);

        // PM
        const PMSource = new Loca.GeoJSONSource({
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [lng, lat]
                },
                properties: {
                  value: PM * 2,
                  data: { lng, lat, NOx, SO2, PM }
                }
              }
            ]
          }
        });
        const PMLayer = new Loca.PrismLayer({
          zIndex: 1000,
          opacity: 1,
          visible: false,
          hasSide: true
        });
        PMLayer.setSource(PMSource);
        PMLayer.setStyle({
          sideTopColor: (index, item) => {
            return item.properties.value > 300 ? GasColor.Danger : GasColor.PM;
          },
          topColor: (index, item) => {
            return item.properties.value > 300 ? GasColor.Danger : GasColor.PM;
          },
          sideBottomColor: GasColor.PM,
          height: (index, item) => {
            return item.properties.value;
          },
          unit: 'meter',
          radius: 2,
          sideNumber: 32
        });

        this.loca.add(PMLayer);
        this.PMLayerList.push(PMLayer);

        PMLayer.addAnimate({
          key: 'height',
          value: [0, 1],
          duration: 500,
          easing: 'Linear'
        });
        PMLayer.show(500);

        // 右侧图表
        this.setChart(lng, lat, NOx, SO2, PM);
      }, 60000);
    },
    async getPointRecord(index) {
      try {
        clearInterval(this.interval);
        this.interval = null;
        this.car.hide();
        this.trackList = [];
        this.SO2List = [];
        this.NOList = [];
        this.NOxList = [];
        this.PMList = [];
        this.NOxLayerList.forEach(item => {
          this.loca.remove(item);
        });
        this.SO2LayerList.forEach(item => this.loca.remove(item));
        this.PMLayerList.forEach(item => this.loca.remove(item));
        this.NOxLayerList = [];
        this.SO2LayerList = [];
        this.PMLayerList = [];
        // const dataList = await getPointRecordApi();
        // if (!dataList.length) return;
        // const pointData = dataList[dataList.length - 1]['point' + index];
        // const [lng, lat] = parseLnglat(pointData.longitude, pointData.latitude);
        // this.addPointPrism(pointData.longitude, pointData.latitude, pointData.nox, pointData.so2);
        // this.setChart(pointData.longitude, pointData.latitude, pointData.nox, pointData.so2);
        this.addPointPrism(121.528068, 38.881448, 20000, 80, 160);
        this.setChart(121.528068, 38.881448, 20000, 90, 160);
      } catch (error) {
        Message.error(parseError(error));
      }
    },
    addPointPrism(lng, lat, NOx, SO2, PM) {
      // NO
      const NOxSource = new Loca.GeoJSONSource({
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [lng + GasLngOffset, lat]
              },
              properties: {
                value: NOx / 100,
                data: { lng, lat, NOx, SO2, PM },
                type: 'NOx'
              }
            }
          ]
        }
      });
      this.NOxLayer = new Loca.PrismLayer({
        zIndex: 1000,
        opacity: 1,
        visible: false,
        hasSide: true
      });
      this.NOxLayer.setSource(NOxSource);
      this.NOxLayer.setStyle({
        sideTopColor: GasColor.NOx,
        topColor: GasColor.NOx,
        sideBottomColor: GasColor.NOx,
        height: (index, item) => {
          return item.properties.value;
        },
        unit: 'meter',
        radius: 4,
        sideNumber: 32
      });
      this.loca.add(this.NOxLayer);
      this.NOxLayerList.push(this.NOxLayer);
      this.NOxLayer.addAnimate({
        key: 'height',
        value: [0, 1],
        duration: 500,
        easing: 'Linear'
      });
      this.NOxLayer.show(500);

      // SO2
      const SO2Source = new Loca.GeoJSONSource({
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [lng - GasLngOffset, lat]
              },
              properties: {
                value: SO2 * 5,
                data: { lng, lat, NOx, SO2, PM },
                type: 'SO2'
              }
            }
          ]
        }
      });
      this.SO2Layer = new Loca.PrismLayer({
        zIndex: 1000,
        opacity: 1,
        visible: false,
        hasSide: true
      });
      this.SO2Layer.setSource(SO2Source);
      this.SO2Layer.setStyle({
        sideTopColor: (index, item) => {
          return item.properties.value > 400 ? GasColor.Danger : GasColor.SO2;
        },
        topColor: (index, item) => {
          return item.properties.value > 400 ? GasColor.Danger : GasColor.SO2;
        },
        sideBottomColor: GasColor.SO2,
        height: (index, item) => {
          return item.properties.value;
        },
        unit: 'meter',
        radius: 4,
        sideNumber: 32
      });
      this.loca.add(this.SO2Layer);
      this.SO2LayerList.push(this.SO2Layer);

      this.SO2Layer.addAnimate({
        key: 'height',
        value: [0, 1],
        duration: 500,
        easing: 'Linear'
      });
      this.SO2Layer.show(500);

      // PM
      if (PM) {
        const PMSource = new Loca.GeoJSONSource({
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [lng, lat]
                },
                properties: {
                  value: PM * 2,
                  data: { lng, lat, NOx, SO2, PM },
                  type: 'PM'
                }
              }
            ]
          }
        });
        this.PMLayer = new Loca.PrismLayer({
          zIndex: 1000,
          opacity: 1,
          visible: false,
          hasSide: true
        });
        this.PMLayer.setSource(PMSource);
        this.PMLayer.setStyle({
          sideTopColor: (index, item) => {
            return item.properties.value > 300 ? GasColor.Danger : GasColor.PM;
          },
          topColor: (index, item) => {
            return item.properties.value > 300 ? GasColor.Danger : GasColor.PM;
          },
          sideBottomColor: GasColor.PM,
          height: (index, item) => {
            return item.properties.value;
          },
          unit: 'meter',
          radius: 4,
          sideNumber: 32
        });
        this.loca.add(this.PMLayer);
        this.PMLayerList.push(this.PMLayer);

        this.PMLayer.addAnimate({
          key: 'height',
          value: [0, 1],
          duration: 500,
          easing: 'Linear'
        });
        this.PMLayer.show(500);
      }
      this.map.setCenter(new AMap.LngLat(lng, lat));
    },
    async addListPrism(list) {
      try {
        list.map(async item => {
          const [lng, lat] = parseLnglat(item.longitude, item.latitude);
          item.lng = lng;
          item.lat = lat;
          return item;
        });
      } catch (error) {
        Message.error(parseError(error));
      }
      // NO;
      const NOxSource = new Loca.GeoJSONSource({
        data: {
          type: 'FeatureCollection',
          features: list.map(item => ({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [item.lng + GasLngOffset, item.lat]
            },
            properties: {
              value: item.nox / 100,
              data: { lng: item.longitude, lat: item.latitude, NOx: item.nox, SO2: item.so2, PM: item.pm },
              type: 'NOx'
            }
          }))
        }
      });
      this.NOxLayer = new Loca.PrismLayer({
        zIndex: 1000,
        opacity: 1,
        visible: false,
        hasSide: true
      });
      this.NOxLayer.setSource(NOxSource);
      this.NOxLayer.setStyle({
        sideTopColor: GasColor.NOx,
        topColor: GasColor.NOx,
        sideBottomColor: GasColor.NOx,
        height: (index, item) => {
          return item.properties.value;
        },
        unit: 'meter',
        radius: 2,
        sideNumber: 32
      });
      this.loca.add(this.NOxLayer);
      this.NOxLayerList.push(this.NOxLayer);
      this.NOxLayer.addAnimate({
        key: 'height',
        value: [0, 1],
        duration: 500,
        easing: 'Linear'
      });
      this.NOxLayer.show(500);

      // SO2
      const SO2Source = new Loca.GeoJSONSource({
        data: {
          type: 'FeatureCollection',
          features: list.map(item => ({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [item.lng - GasLngOffset, item.lat]
            },
            properties: {
              value: item.so2 * 5,
              data: { lng: item.longitude, lat: item.latitude, NOx: item.nox, SO2: item.so2, PM: item.pm },
              type: 'SO2'
            }
          }))
        }
      });
      this.SO2Layer = new Loca.PrismLayer({
        zIndex: 1000,
        opacity: 1,
        visible: false,
        hasSide: true
      });
      this.SO2Layer.setSource(SO2Source);
      this.SO2Layer.setStyle({
        sideTopColor: (index, item) => {
          return item.properties.value > 400 ? GasColor.Danger : GasColor.SO2;
        },
        topColor: (index, item) => {
          return item.properties.value > 400 ? GasColor.Danger : GasColor.SO2;
        },
        sideBottomColor: GasColor.SO2,
        height: (index, item) => {
          return item.properties.value;
        },
        unit: 'meter',
        radius: 2,
        sideNumber: 32
      });
      this.loca.add(this.SO2Layer);
      this.SO2LayerList.push(this.SO2Layer);

      this.SO2Layer.addAnimate({
        key: 'height',
        value: [0, 1],
        duration: 500,
        easing: 'Linear'
      });
      this.SO2Layer.show(500);

      // PM
      const PMSource = new Loca.GeoJSONSource({
        data: {
          type: 'FeatureCollection',
          features: list.map(item => ({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [item.lng, item.lat]
            },
            properties: {
              value: item.pm * 2,
              data: { lng: item.longitude, lat: item.latitude, NOx: item.nox, SO2: item.so2, PM: item.pm },
              type: 'PM'
            }
          }))
        }
      });
      this.PMLayer = new Loca.PrismLayer({
        zIndex: 1000,
        opacity: 1,
        visible: false,
        hasSide: true
      });
      this.PMLayer.setSource(PMSource);
      this.PMLayer.setStyle({
        sideTopColor: (index, item) => {
          return item.properties.value > 300 ? GasColor.Danger : GasColor.PM;
        },
        topColor: (index, item) => {
          return item.properties.value > 300 ? GasColor.Danger : GasColor.PM;
        },
        sideBottomColor: GasColor.PM,
        height: (index, item) => {
          return item.properties.value;
        },
        unit: 'meter',
        radius: 2,
        sideNumber: 32
      });
      this.loca.add(this.PMLayer);
      this.PMLayerList.push(this.PMLayer);

      this.PMLayer.addAnimate({
        key: 'height',
        value: [0, 1],
        duration: 500,
        easing: 'Linear'
      });
      this.PMLayer.show(500);
    },
    async addListSinglePrism(list, type) {
      try {
        list.map(async item => {
          const [lng, lat] = parseLnglat(item.longitude, item.latitude);
          item.lng = lng;
          item.lat = lat;
          return item;
        });
      } catch (error) {
        Message.error(parseError(error));
      }

      // NO;
      if (type === 'NOx') {
        const NOxSource = new Loca.GeoJSONSource({
          data: {
            type: 'FeatureCollection',
            features: list.map(item => ({
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [item.lng, item.lat]
              },
              properties: {
                value: item.nox / 100,
                data: { lng: item.longitude, lat: item.latitude, NOx: item.nox, SO2: item.so2, PM: item.pm },
                type: 'NOx'
              }
            }))
          }
        });
        this.NOxLayer = new Loca.PrismLayer({
          zIndex: 1000,
          opacity: 1,
          visible: false,
          hasSide: true
        });
        this.NOxLayer.setSource(NOxSource);
        this.NOxLayer.setStyle({
          sideTopColor: GasColor.NOx,
          topColor: GasColor.NOx,
          sideBottomColor: GasColor.NOx,
          height: (index, item) => {
            return item.properties.value;
          },
          unit: 'px',
          radius: 4,
          sideNumber: 32
        });
        this.loca.add(this.NOxLayer);
        this.NOxLayerList.push(this.NOxLayer);
        this.NOxLayer.addAnimate({
          key: 'height',
          value: [0, 1],
          duration: 500,
          easing: 'Linear'
        });
        this.NOxLayer.show(500);
      }

      // SO2
      if (type === 'SO2') {
        const SO2Source = new Loca.GeoJSONSource({
          data: {
            type: 'FeatureCollection',
            features: list.map(item => ({
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [item.lng, item.lat]
              },
              properties: {
                value: item.so2 * 5,
                data: { lng: item.longitude, lat: item.latitude, NOx: item.nox, SO2: item.so2, PM: item.pm },
                type: 'SO2'
              }
            }))
          }
        });
        this.SO2Layer = new Loca.PrismLayer({
          zIndex: 1000,
          opacity: 1,
          visible: false,
          hasSide: true
        });
        this.SO2Layer.setSource(SO2Source);
        this.SO2Layer.setStyle({
          sideTopColor: (index, item) => {
            return item.properties.value > 400 ? GasColor.Danger : GasColor.SO2;
          },
          topColor: (index, item) => {
            return item.properties.value > 400 ? GasColor.Danger : GasColor.SO2;
          },
          sideBottomColor: (index, item) => {
            return item.properties.value > 400 ? GasColor.Danger : GasColor.SO2;
          },
          height: (index, item) => {
            return item.properties.value;
          },
          unit: 'px',
          radius: 4,
          sideNumber: 32
        });
        this.loca.add(this.SO2Layer);
        this.SO2LayerList.push(this.SO2Layer);

        this.SO2Layer.addAnimate({
          key: 'height',
          value: [0, 1],
          duration: 500,
          easing: 'Linear'
        });
        this.SO2Layer.show(500);
      }

      // PM
      if (type === 'PM') {
        const PMSource = new Loca.GeoJSONSource({
          data: {
            type: 'FeatureCollection',
            features: list.map(item => ({
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [item.lng, item.lat]
              },
              properties: {
                value: item.pm * 2,
                data: { lng: item.longitude, lat: item.latitude, NOx: item.nox, SO2: item.so2, PM: item.pm },
                type: 'PM'
              }
            }))
          }
        });
        this.PMLayer = new Loca.PrismLayer({
          zIndex: 1000,
          opacity: 1,
          visible: false,
          hasSide: true
        });
        this.PMLayer.setSource(PMSource);
        this.PMLayer.setStyle({
          sideTopColor: (index, item) => {
            return item.properties.value > 300 ? GasColor.Danger : GasColor.PM;
          },
          topColor: (index, item) => {
            return item.properties.value > 300 ? GasColor.Danger : GasColor.PM;
          },
          sideBottomColor: (index, item) => {
            return item.properties.value > 300 ? GasColor.Danger : GasColor.PM;
          },
          height: (index, item) => {
            return item.properties.value * 0.5;
          },
          unit: 'px',
          radius: 4,
          sideNumber: 32
        });
        this.loca.add(this.PMLayer);
        this.PMLayerList.push(this.PMLayer);

        this.PMLayer.addAnimate({
          key: 'height',
          value: [0, 1],
          duration: 500,
          easing: 'Linear'
        });
        this.PMLayer.show(500);
      }
    },
    setChart(lng, lat, NOx, SO2, PM) {
      this.tableShow = false;
      this.sensorSelected = {
        NOx: NOx.toFixed(2),
        SO2: SO2 ? SO2.toFixed(2) : 0,
        PM: PM ? PM.toFixed(2) : 0,
        lng: lng,
        lat: lat
      };
    },
    setMapData(data, type) {
      this.tableShow = false;
      clearInterval(this.interval);
      this.interval = null;
      this.car.hide();
      this.trackList = [];
      this.SO2List = [];
      this.NOList = [];
      this.NOxList = [];
      this.PMList = [];
      this.NOxLayerList.forEach(item => {
        this.loca.remove(item);
      });
      this.SO2LayerList.forEach(item => this.loca.remove(item));
      this.PMLayerList.forEach(item => this.loca.remove(item));
      this.NOxLayerList = [];
      this.SO2LayerList = [];
      this.PMLayerList = [];
      this.addListSinglePrism(data, type);
    },
    resetLayer() {
      clearInterval(this.interval);
      this.interval = null;
      this.car.hide();
      this.trackList = [];
      this.SO2List = [];
      this.NOList = [];
      this.NOxList = [];
      this.PMList = [];
      this.NOxLayerList.forEach(item => {
        this.loca.remove(item);
      });
      this.SO2LayerList.forEach(item => this.loca.remove(item));
      this.PMLayerList.forEach(item => this.loca.remove(item));
      this.NOxLayerList = [];
      this.SO2LayerList = [];
      this.PMLayerList = [];
      if (this.historyLayer) this.loca.remove(this.historyLayer);
      if (this.breathRed) this.loca.remove(this.breathRed);
      this.historyLayer = null;
    },
    setPointData(index) {
      // this.loca.animate.stop();
      this.resetLayer();
      const demoData = [
        { lng: 121.845071, lat: 38.969038, NOx: 857, SO2: 1, PM: 1 },
        { lng: 121.845119, lat: 38.97025, NOx: 746, SO2: 1, PM: 1 },
        { lng: 121.846699, lat: 38.970206, NOx: 551, SO2: 1, PM: 1 }
      ];
      const point = demoData[index - 1];
      this.addNOPrism(point.lng, point.lat, point.NOx);
      this.setChart(point.lng, point.lat, point.NOx);
    },
    addNOPrism(lng, lat, NOx) {
      // NO
      const NOxSource = new Loca.GeoJSONSource({
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [lng, lat]
              },
              properties: {
                value: NOx,
                data: { lng, lat, NOx },
                type: 'NOx'
              }
            }
          ]
        }
      });
      this.NOxLayer = new Loca.PrismLayer({
        zIndex: 1000,
        opacity: 1,
        visible: false,
        hasSide: true
      });
      this.NOxLayer.setSource(NOxSource);
      this.NOxLayer.setStyle({
        sideTopColor: GasColor.NOx,
        topColor: GasColor.NOx,
        sideBottomColor: GasColor.NOx,
        height: (index, item) => {
          return item.properties.value / 5;
        },
        unit: 'px',
        radius: 4,
        sideNumber: 32
      });
      this.loca.add(this.NOxLayer);
      this.NOxLayerList.push(this.NOxLayer);
      this.NOxLayer.addAnimate({
        key: 'height',
        value: [0, 1],
        duration: 500,
        easing: 'Linear'
      });
      this.NOxLayer.show(500);

      this.map.setCenter(new AMap.LngLat(lng, lat));
    },
    getSource() {
      const source = new Loca.GeoJSONSource({
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [121.834258, 38.974014]
              }
            }
          ]
        },
        url: 'https://a.amap.com/Loca/static/loca-v2/demos/mock_data/sz_road_F.json'
      });
      this.breathRed = new Loca.ScatterLayer({
        loca: this.loca,
        zIndex: 113,
        opacity: 1,
        visible: true,
        zooms: [2, 22]
      });
      this.breathRed.setSource(source);
      this.breathRed.setStyle({
        unit: 'px',
        size: [60, 60],
        borderWidth: 0,
        texture: 'https://a.amap.com/Loca/static/loca-v2/demos/images/breath_red.png',
        duration: 500,
        animate: true
      });
      this.map.setCenter(new AMap.LngLat(121.834258, 38.974014));
      this.loca.animate.start();
    }
  },
  beforeDestroy() {
    this.releaseSocket();
    if (this.interval) clearInterval(this.interval);
    this.interval = null;
  }
};
</script>

<style lang="scss" scoped>
.map-container {
  width: 100vw;
  height: 100vh;
  &_content {
    width: 100vw;
    height: 100vh;
  }
}
.button-group {
  position: fixed;
  bottom: 0;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.search-box {
  position: fixed;
  top: 60px;
  left: 60px;
}
.transition-box {
  position: fixed;
  height: calc(100vh - 80px);
  top: 40px;
  display: flex;
  right: 20px;
  flex-direction: column;
  justify-content: space-between;
  &_table {
    width: 50vw;
    height: 100%;
  }
  &_chart {
    width: 60vh;
    height: 30vh;
  }
  &_data {
    width: 60vh;
    height: 30vh;
  }
  &--left {
    bottom: 40px;
    left: 20px;
    right: auto;
    top: auto;
    height: 28vh;
  }
}

.slide-fade-enter-active {
  transition: all 0.25s linear;
}
.slide-fade-leave-active {
  transition: all 0.25s linear;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(100px);
  opacity: 0;
}

.slide-fade-left-enter-active {
  transition: all 0.25s linear;
}
.slide-fade-left-leave-active {
  transition: all 0.25s linear;
}
.slide-fade-left-enter, .slide-fade-left-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(-100px);
  opacity: 0;
}
</style>

<style lang="scss">
.amap-logo {
  display: none !important;
}
.amap-copyright {
  display: none !important;
}
.amap-sug-result {
  top: 0;
}
</style>
