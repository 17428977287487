<template>
  <div class="data-chart"
    id="chart"
    ref="chart">
    <e-chart class="data-chart_item"
      :option="NOxOption"
      v-show="sensorSelected&&sensorSelected.NOx"></e-chart>
    <e-chart class="data-chart_item"
      :option="SO2Option"
      v-show="sensorSelected&&sensorSelected.SO2"></e-chart>
    <e-chart class="data-chart_item"
      :option="PMOption"
      v-show="sensorSelected&&sensorSelected.PM"></e-chart>
    <!-- <div class="data-chart_item" id="chartNOx" ref="chartNOx"></div>
    <div class="data-chart_item" id="chartSO2" ref="chartSO2"></div>
    <div class="data-chart_item" id="chartPM" ref="chartPM"></div> -->
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { GasColor } from '@/constants/common';
import EChart from '@/components/EChart.vue';
export default {
  components: { EChart },
  props: {
    sensorSelected: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      option: {
        grid: {
          left: '12%'
        },
        tooltip: {
          show: true
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: [
          {
            type: 'value',
            name: 'NOx'
            // splitNumber: 5,
            // max: 12000
          },
          {
            type: 'value',
            name: 'SO2'
            // splitNumber: 5,
            // max: 30
          }
        ],
        legend: {
          data: [
            {
              name: 'NOx'
            },
            {
              name: 'SO2'
            }
          ],
          bottom: 10,
          textStyle: {
            color: '#ffffff',
            fontSize: 12
          }
        },
        series: [
          {
            data: [],
            type: 'bar',
            barWidth: 20,
            yAxisIndex: 0,
            color: '#0091ff',
            barGap: '100%',
            name: 'NOx'
          },
          {
            data: [],
            type: 'bar',
            barWidth: 20,
            yAxisIndex: 1,
            color: '#ff8500',
            barGap: '100%',
            name: 'SO2'
          }
        ],
        textStyle: {
          color: '#ffffff'
        }
      },
      NOxOption: {
        grid: {
          left: '12%'
        },
        tooltip: {
          show: true
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        legend: {
          bottom: 10,
          textStyle: {
            color: '#ffffff',
            fontSize: 12
          }
        },
        series: [],
        textStyle: {
          color: '#ffffff'
        }
      },
      SO2Option: {
        grid: {
          left: '12%'
        },
        tooltip: {
          show: true
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        legend: {
          bottom: 10,
          textStyle: {
            color: '#ffffff',
            fontSize: 12
          }
        },
        series: [],
        textStyle: {
          color: '#ffffff'
        }
      },
      PMOption: {
        grid: {
          left: '12%'
        },
        tooltip: {
          show: true
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        legend: {
          bottom: 10,
          textStyle: {
            color: '#ffffff',
            fontSize: 12
          }
        },
        series: [],
        textStyle: {
          color: '#ffffff'
        }
      },
      chart: null
    };
  },
  watch: {
    sensorSelected(value) {
      if (!value) return;
      // this.chart.resize();
      this.setChart();
    }
  },
  mounted() {
    // this.initChart();
  },
  methods: {
    initChart() {
      const chart = this.$refs.chart;
      this.chart = echarts.init(chart);
      this.chart.setOption(this.option);
    },
    setChart() {
      // if (this.type === 'PM') {
      //   const option = {
      //     yAxis: [
      //       {
      //         type: 'value',
      //         name: 'PM'
      //         // splitNumber: 5,
      //         // max: 12000
      //       }
      //     ],
      //     legend: {
      //       data: [
      //         {
      //           name: 'PM'
      //         }
      //       ],
      //       bottom: 10,
      //       textStyle: {
      //         color: '#ffffff',
      //         fontSize: 12
      //       }
      //     },
      //     series: [
      //       {
      //         data: this.sensorSelected.PM ? [this.sensorSelected.PM] : [],
      //         type: 'bar',
      //         barWidth: 20,
      //         yAxisIndex: 0,
      //         color: GasColor.PM
      //       }
      //     ]
      //   };
      // } else {
      //   const option = {
      //     series: [
      //       {
      //         data: this.sensorSelected.NOx ? [this.sensorSelected.NOx] : [],
      //         type: 'bar',
      //         barWidth: 20,
      //         yAxisIndex: 0,
      //         color: GasColor.NOx
      //       },
      //       {
      //         data: this.sensorSelected.SO2 ? [this.sensorSelected.SO2] : [],
      //         type: 'bar',
      //         barWidth: 20,
      //         yAxisIndex: 1,
      //         color: GasColor.SO2
      //       }
      //     ]
      //   };
      //   this.chart.setOption(option);
      // }
      this.NOxOption = {
        grid: {
          left: 50,
          right: 0,
          top: 20,
          bottom: 40
        },
        tooltip: {
          show: true
        },
        xAxis: {
          type: 'category',
          data: ['NOx']
        },
        yAxis: {
          type: 'value'
        },
        legend: {
          bottom: 10,
          textStyle: {
            color: '#ffffff',
            fontSize: 12
          }
        },
        textStyle: {
          color: '#ffffff'
        },
        series: [
          {
            data: this.sensorSelected.NOx ? [this.sensorSelected.NOx] : [],
            type: 'bar',
            barWidth: 20,
            color: GasColor.NOx
          }
        ]
      };
      this.SO2Option = {
        grid: {
          left: 50,
          right: 0,
          top: 20,
          bottom: 40
        },
        tooltip: {
          show: true
        },
        xAxis: {
          type: 'category',
          data: ['SO2']
        },
        yAxis: {
          type: 'value'
        },
        legend: {
          bottom: 10,
          textStyle: {
            color: '#ffffff',
            fontSize: 12
          }
        },
        textStyle: {
          color: '#ffffff'
        },
        series: [
          {
            data: this.sensorSelected.SO2 ? [this.sensorSelected.SO2] : [],
            type: 'bar',
            barWidth: 20,
            markLine:
              /* eslint-disable */
              this.sensorSelected.SO2 && this.sensorSelected.SO2 > 80
                ? {
                    silent: false,
                    symbol: 'circle',
                    label: {
                      position: 'start',
                      formatter: '超标',
                      color: '#ff0000',
                      fontWeight: 'bold',
                      distance: 24
                    },
                    lineStyle: {
                      color: '#ff0000'
                    },
                    data: [
                      {
                        name: '超标',
                        yAxis: 80
                      }
                    ],
                    animation: false
                  }
                : {}
            /* eslint-disable */
          }
        ],
        visualMap: [
          {
            show: false,
            orient: 'horizontal',
            bottom: 10,
            right: 'center',
            pieces: [
              {
                gt: 0,
                lte: 80,
                color: GasColor.SO2
              },
              {
                gt: 80,
                color: GasColor.Danger
              }
            ],
            outOfRange: {
              color: GasColor.Danger
            }
            // type: 'piecewise'
          }
        ]
      };
      this.PMOption = {
        grid: {
          left: 50,
          right: 0,
          top: 20,
          bottom: 40
        },
        tooltip: {
          show: true
        },
        xAxis: {
          type: 'category',
          data: ['PM']
        },
        yAxis: {
          type: 'value'
        },
        legend: {
          bottom: 10,
          textStyle: {
            color: '#ffffff',
            fontSize: 12
          }
        },
        textStyle: {
          color: '#ffffff'
        },
        series: [
          {
            data: this.sensorSelected.PM ? [this.sensorSelected.PM] : [],
            type: 'bar',
            barWidth: 20,
            markLine:
              /* eslint-disable */
              this.sensorSelected.PM && this.sensorSelected.PM > 150
                ? {
                    silent: false,
                    symbol: 'circle',
                    label: {
                      position: 'start',
                      formatter: '超标',
                      color: '#ff0000',
                      fontWeight: 'bold',
                      distance: 24
                    },
                    lineStyle: {
                      color: '#ff0000'
                    },
                    data: [
                      {
                        name: '超标',
                        yAxis: 150
                      }
                    ],
                    animation: false
                  }
                : {}
            /* eslint-disable */
          }
        ],
        visualMap: [
          {
            show: false,
            orient: 'horizontal',
            bottom: 10,
            right: 'center',
            pieces: [
              {
                gt: 0,
                lte: 150,
                color: GasColor.PM
              },
              {
                gt: 150,
                color: GasColor.Danger
              }
            ],
            outOfRange: {
              color: GasColor.Danger
            }
            // type: 'piecewise'
          }
        ]
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.data-chart {
  height: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;

  &_item {
    flex: 1;
  }
}
</style>
