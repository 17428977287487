<template>
  <div class="info-box"
    :style="{ top: infoTop + 'px', left: infoLeft + 'px' }"
    v-if="sensorHover">
    <div class="info-box_content">位置:{{ sensorHover.lnglat[0] }},{{ sensorHover.lnglat[1] }}</div>
    <div class="info-box_content">数值:{{ sensorHover.value }}</div>
  </div>
</template>

<script>
export default {
  props: {
    sensorHover: {
      type: Object,
      default: null
    }
  }
};
</script>

<style lang="scss">
.info-box {
  position: fixed;
  z-index: 5000;
  padding: 20px;
  background: #3b3b3b;
  color: #ffffff;
  text-align: left;
  &_content {
  }
}
</style>
