<template>
  <div class="echart-container"
    ref="chart"
    :style="{ height: height + 'px' }"></div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  props: {
    option: {
      type: Object,
      default() {
        return {};
      }
    },
    height: {
      type: String || Number,
      default: ''
    }
  },
  data() {
    return {
      chart: null
    };
  },
  watch: {
    option: {
      handler(value) {
        this.setChart();
      },
      deep: true
    }
  },
  mounted() {
    this.chart = echarts.init(this.$refs.chart);
    window.addEventListener('resize', this.onResize);
  },
  methods: {
    setChart() {
      this.onResize();
      this.chart.setOption(this.option, true);
    },
    onResize() {
      this.chart.resize();
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  }
};
</script>

<style lang="scss" scoped></style>
