// const Sensors1 = [
//   [121.8469444, 38.97, 96.1502, 152.519],
//   [121.8444444, 38.96972222, 329.021, 436.285],
//   [121.8416667, 38.96388889, 572.667, 703.667],
//   [121.8411111, 38.95805556, 705.009, 857.12],
//   [121.8441667, 38.95555556, 498.944, 630.195]
// ];

// const Sensors2 = [
//   [121.8388889, 38.97, 292.183, 393.615],
//   [121.84, 38.96444444, 131.925, 210.499],
//   [121.8402778, 38.96722222, 244.492, 334.982]
// ];

// const Sensors3 = [
//   [121.8394444, 38.99138889, 165.185, 252.349],
//   [121.8388889, 38.98888889, 111.492, 191.638],
//   [121.8341667, 38.98916667, 194.99, 286.548]
// ];

const Sensors = [
  [121.527907, 38.880936, 8902, 11.7],
  [121.526583, 38.880931, 9011, 28.9],
  [121.525603, 38.882451, 10018, 7.2]
];

const CarData = [
  [121.851316, 39.078096],
  [121.848992, 39.078125],
  [121.848809, 39.07591003],
  [121.8488114, 39.07470795],
  [121.8487814, 39.07328284],
  [121.8488492, 39.07126427],
  [121.8488554, 39.06683515],

  [121.8519555, 39.06687384],
  [121.8548358, 39.06688059],
  [121.8581145, 39.06685378],
  [121.8581355, 39.06835179],
  [121.8581657, 39.07028917],
  [121.8581167, 39.07135485],
  [121.8606117, 39.07131378],
  [121.862186, 39.07123816],
  [121.8640441, 39.07125226]
];

const CarHourData = [
  [121.851316, 39.078096],
  // [121.848992, 39.078125],
  [121.848809, 39.07591003],
  [121.8488114, 39.07470795],
  [121.8487814, 39.07328284]

  // [121.8487814, 39.07328284],
  // [121.8488114, 39.07470795],
  // [121.848809, 39.07591003],
  // [121.848992, 39.078125],
  // [121.851316, 39.078096]
];

export { Sensors, CarData, CarHourData };
