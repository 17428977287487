<template>
  <div class="map-container"
    :class="{'track-mode':isTrackMode}"
    v-loading="loading">
    <div class="map-container"
      id="local-map"></div>
    <div class="buttons">
      <div v-if="!isTrackMode">
        <el-button type="primary"
          plain
          @click="getPointData(1)">监测点1</el-button>
        <el-button type="primary"
          plain
          @click="getPointData(2)">监测点2</el-button>
        <el-button type="primary"
          plain
          @click="getPointData(3)">监测点3</el-button>
        <el-button type="primary"
          plain
          @click="trace">溯源</el-button>
        <el-button type="primary"
          plain
          @click="checkTrack">行车记录</el-button>
        <el-dropdown>
          <el-button type="primary"
            plain
            style="margin-left: 10px">{{ historyMode }}</el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="trace()">
              <el-button type="text">近一小时</el-button>
            </el-dropdown-item>
            <el-dropdown-item @click.native="checkDay()">
              <el-button type="text">近一天</el-button>
            </el-dropdown-item>
            <el-dropdown-item @click.native="checkMore()">
              <el-button type="text">更多</el-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div v-else>
        <el-button type="primary"
          plain
          @click="stopTrack">退出轨迹</el-button>
      </div>
    </div>
    <div class="info-box"
      :style="{ top: infoTop + 'px', left: infoLeft + 'px' }"
      v-if="sensorHover">
      <div class="info-box-position">位置:{{ sensorHover.lnglat[0] }},{{ sensorHover.lnglat[1] }}</div>
      <div class="info-box-value">数值:{{ sensorHover.value }}</div>
    </div>
    <chart-box class="chart"
      :class="{ 'chart-show': sensorSelected }"
      top="40px"
      right="20px">
      <div class="chart"
        id="chart"
        ref="chart"></div>
    </chart-box>
    <chart-box class="chart"
      :class="{ 'chart-show': sensorSelected }"
      bottom="40px"
      right="20px">
      <div class="chart-info">
        <div class="chart-info-item"
          v-if="sensorSelected">测点经度: {{ sensorSelected.longitude }}</div>
        <div class="chart-info-item"
          v-if="sensorSelected">测点纬度: {{ sensorSelected.latitude }}</div>
        <div class="chart-info-item"
          v-if="sensorSelected">NOx浓度: {{ sensorSelected.values[1] }}</div>
        <div class="chart-info-item"
          v-if="sensorSelected">SO2浓度: {{ sensorSelected.values[0] }}</div>
      </div>
    </chart-box>
    <transition name="slide-fade"
      :duration="1000"
      mode="out-in">
      <chart-box class="table-show"
        height="700px"
        top="120px"
        width="800px"
        v-if="tableShow">
        <div class="data-table">
          <div class="search-form">
            <el-form inline>
              <el-form-item>
                <el-date-picker type="datetimerange"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  v-model="range"
                  :default-value="defaultTime"
                  format="yyyy-MM-dd HH:mm"></el-date-picker>
              </el-form-item>
            </el-form>
          </div>
          <el-table :data="dataList">
            <el-table-column type="index"></el-table-column>
            <el-table-column prop="longitude"
              label="经度"></el-table-column>
            <el-table-column prop="latitude"
              label="纬度"></el-table-column>
            <el-table-column prop="NOx"
              label="NOx浓度"></el-table-column>
            <el-table-column prop="SO2"
              label="SO2浓度"></el-table-column>
            <el-table-column prop="createdAt"
              label="时间"></el-table-column>
          </el-table>
          <el-pagination class="pagination"
            background
            @size-change="pageSizeChangeHandler"
            @current-change="currentPageChangeHandler"
            :current-page.sync="page.currentPage"
            :page-size.sync="page.pageSize"
            :page-sizes="[5, 10, 20, 50, 100]"
            layout="total, prev, pager, next, jumper"
            :total="page.totalCount"
            v-if="page.totalCount"></el-pagination>
        </div>
      </chart-box>
    </transition>
    <input type="text"
      class="search-box"
      id="search">
  </div>
</template>

<script>
import AMap from 'AMap';
import Loca from 'Loca';
import { CarData, Sensors } from './realData.js';
import * as echarts from 'echarts';
import ChartBox from './chartBox';
import dayjs from 'dayjs';
import carData from './car.json';
import { io } from 'socket.io-client';
import { Message } from 'element-ui';
import { getHistoryApi } from '@/apis/history.js';
export default {
  components: { ChartBox },
  data() {
    return {
      poslnglat: {},
      longitude: 0,
      latitude: 0,
      adcode: 0,
      map: null,
      marker: null,
      toolbar: null,
      clickListener: null,
      autoOptions: null,
      autoComplete: null,
      autoResult: [],
      keywords: '',
      searchList: [],
      geocoder: null,
      inputPosition: [],
      layer: null,
      sensorHover: null,
      sensorSelected: null,
      infoTop: '',
      infoLeft: '',
      chart: '',
      interval: null,
      originLayer: null,
      historyMode: '历史记录',
      tableShow: false,
      dataList: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        totalCount: 50
      },
      range: [new Date(new Date().toLocaleDateString()), new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000)],
      gpsSocket: null,
      NOxSocket: null,
      SO2Socket: null,
      // 数据可视化核心控制
      loca: null,
      // 柱图层
      pl: null,
      dat: null,
      gasData: [],
      isTrackMode: false,
      trackList: [],
      car: null,
      NOLayer: null,
      gasLayer: null,
      SO2Layer: null,
      NOxLayer: null,
      pointData: {
        longitude: null,
        latitude: null,
        NO: null,
        SO2: null
      },
      NOLayerList: [],
      loading: false
    };
  },
  mounted() {
    this.initMap();
    this.initCar();
    this.initLoca();
    this.initChart();
  },
  watch: {
    pointData: {
      handler(value) {
        if (this.pointData.longitude && this.pointData.latitude && this.pointData.NO && this.pointData.SO2) {
          this.loading = false;
          this.setPointData();
        }
      },
      deep: true
    }
  },
  methods: {
    initChart() {
      const chart = this.$refs.chart;
      this.chart = echarts.init(chart);
      const option = {
        grid: {
          left: '12%'
        },
        // tooltip: {
        //   show: true
        // },
        xAxis: {
          type: 'category',
          data: this.sensorSelected?.point ? [this.sensorSelected.point] : []
        },
        yAxis: [
          {
            type: 'value',
            name: 'NOx'
            // splitNumber: 5,
            // max: 12000
          },
          {
            type: 'value',
            name: 'SO2'
            // splitNumber: 5,
            // max: 30
          }
        ],
        // {
        //   type: 'value',
        //   name: 'SO2',
        //   splitNumber: 5
        //   // max: 50
        // }
        legend: {
          data: [
            {
              name: 'NOx'
            },
            {
              name: 'SO2'
            }
          ],
          bottom: 10,
          textStyle: {
            color: '#ffffff',
            fontSize: 12
          }
        },
        series: [
          {
            data: this.sensorSelected?.values.filter((_, index) => !index) || [],
            type: 'bar',
            barWidth: 40,
            yAxisIndex: 1,
            color: '#0091ff',
            barGap: '100%',
            name: 'NOx'
          },
          {
            data: this.sensorSelected?.values.filter((_, index) => !index) || [],
            type: 'bar',
            barWidth: 40,
            yAxisIndex: 0,
            color: '#ff8500',
            barGap: '100%',
            name: 'SO2'
          }
        ],
        textStyle: {
          color: '#ffffff'
        }
      };
      this.chart.setOption(option);
    },
    initMap() {
      // 地图主体
      this.map = new AMap.Map('local-map', {
        resizeEnable: true,
        zoom: 18, //级别
        viewMode: '3D', //使用3D视图
        // center: [108.7570724, 34.23283386],
        center: [121.528068, 38.881448],
        // layers: [new AMap.TileLayer.Satellite()],
        mapStyle: 'amap://styles/8ae6250b21f82d50499840d20be9e518',
        pitch: 45,
        features: ['bg', 'road', 'building', 'point'],
        pitchEnable: true,
        showBuildingBlock: true
      });

      // 引入插件
      // 地图缩放
      AMap.plugin(['AMap.ToolBar'], () => {
        this.map.addControl(new AMap.ToolBar());
      });

      // 移动动画
      AMap.plugin('AMap.MoveAnimation', item => {});
      AMap.plugin('AMap.AutoComplete', () => {
        // 实例化AutoComplete
        var autoOptions = {
          // input 为绑定输入提示功能的input的DOM ID
          input: 'search'
        };
        var autoComplete = new AMap.AutoComplete(autoOptions);
        // 无需再手动执行search方法，autoComplete会根据传入input对应的DOM动态触发search
        autoComplete.on('select', e => {
          this.map.setCenter(new AMap.LngLat(e.poi.location.lng, e.poi.location.lat));
        });
      });

      // 引入区域编码
      AMap.plugin('AMap.Geocoder', () => {
        this.geocoder = new AMap.Geocoder({
          city: '全国'
        });
      });

      this.map.on('click', e => {
        this.sensorSelected = null;
      });
      const marker1 = new AMap.Marker({
        position: [119.175548, 36.713355]
      });
      const marker2 = new AMap.Marker({
        position: [119.17556163257815, 36.713365707313706]
      });
      // const marker2 = new AMap.LngLat(119.17556163257815, 36.713365707313706);
      this.map.add(marker1);
      this.map.add(marker2);
    },
    initCar() {
      this.car = new AMap.Marker({
        map: this.map,
        position: [121.528092, 38.880949],
        icon: 'https://a.amap.com/jsapi_demos/static/demo-center-v2/car.png',
        offset: new AMap.Pixel(-26, -13),
        visible: false,
        autoRotation: true
        // angle: 90
      });
      // this.car.on('moving', e => {
      //   this.map.setCenter(e.target.getPosition(), true);
      // });
    },
    initLoca() {
      this.loca = new Loca.Container({
        map: this.map
      });
      this.loca.ambLight = {
        intensity: 0.7,
        color: '#7b7bff'
      };
      this.loca.dirLight = {
        intensity: 0.8,
        color: '#fff',
        target: [0, 0, 0],
        position: [0, -1, 1]
      };
      this.loca.pointLight = {
        color: 'rgb(240,88,25)',
        position: [112.028276, 31.58538, 2000000],
        intensity: 3,
        // 距离表示从光源到光照强度为 0 的位置，0 就是光不会消失。
        distance: 5000000
      };
      this.dat = new Loca.Dat();
      // this.dat.addLight(this.loca.ambLight, this.loca, '环境光');
      // this.dat.addLight(this.loca.dirLight, this.loca, '平行光');
      // this.dat.addLight(this.loca.pointLight, this.loca, '点光');
    },
    polling() {
      if (!this.interval) {
        this.interval = setInterval(() => {
          this.setData();
          if (this.sensorSelected) {
            this.sensorSelected = this.sensorList.find(item => item.longitude == this.sensorSelected.longitude && item.latitude == this.sensorSelected.latitude);
          }
          this.setChart();
        }, 5000);
      }
    },
    setChart() {
      const option = {
        series: [
          {
            data: this.sensorSelected?.values.filter((_, index) => index) || [],
            type: 'bar',
            barWidth: 40,
            yAxisIndex: 0,
            color: '#0091ff'
          },
          {
            data: this.sensorSelected?.values.filter((_, index) => !index) || [],
            type: 'bar',
            barWidth: 40,
            yAxisIndex: 1,
            color: '#ff8500'
          }
        ]
      };
      this.chart.setOption(option);
    },
    setRealData(num) {
      this.historyMode = '历史记录';
      this.tableShow = false;
      const jsonSource = new Loca.GeoJSONSource({
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [Sensors[num - 1][0] - 0.00002, Sensors[num - 1][1]]
              },
              properties: {
                NOx: Sensors[num - 1][2],
                SO2: Sensors[num - 1][3]
              }
            }
          ]
        }
      });
      const jsonSource1 = new Loca.GeoJSONSource({
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [Sensors[num - 1][0] + 0.00002, Sensors[num - 1][1]]
              },
              properties: {
                NOx: Sensors[num - 1][2],
                SO2: Sensors[num - 1][3]
              }
            }
          ]
        }
      });
      if (this.NOxLayer) {
        this.loca.remove(this.NOxLayer);
      }
      if (this.SO2Layer) {
        this.loca.remove(this.SO2Layer);
      }
      if (!this.gasLayer) {
        this.NOxLayer = new Loca.PrismLayer({
          // loca: this.loca,
          zIndex: 1000,
          opacity: 1,
          visible: false,
          hasSide: true
        });
        this.SO2Layer = new Loca.PrismLayer({
          // loca: this.loca,
          zIndex: 1000,
          opacity: 1,
          visible: false,
          hasSide: true
        });
        this.NOxLayer.setSource(jsonSource);
        this.SO2Layer.setSource(jsonSource1);
        this.NOxLayer.setStyle({
          sideTopColor: '#0091ff',
          topColor: '#0091ff',
          sideBottomColor: '#0091ff',
          height: (index, item) => {
            return item.properties.NOx / 100;
          },
          unit: 'meter',
          radius: 2,
          sideNumber: 32
        });
        this.SO2Layer.setStyle({
          sideTopColor: '#ff8500',
          topColor: '#ff8500',
          sideBottomColor: '#ff8500',
          height: (index, item) => {
            return item.properties.SO2;
          },
          unit: 'meter',
          radius: 2,
          sideNumber: 32
        });
        this.loca.add(this.NOxLayer);
        this.loca.add(this.SO2Layer);
        this.NOxLayer.show(500);
        this.NOxLayer.addAnimate({
          key: 'height',
          value: [0, 1],
          duration: 1000,
          easing: 'Linear'
        });
        this.SO2Layer.show(500);
        this.SO2Layer.addAnimate({
          key: 'height',
          value: [0, 1],
          duration: 1000,
          easing: 'Linear'
        });
      }
      this.sensorSelected = {
        values: []
      };
      this.sensorSelected.values[1] = Sensors[num - 1][2].toFixed(2);
      this.sensorSelected.values[0] = Sensors[num - 1][3].toFixed(2);
      this.sensorSelected.longitude = Sensors[num - 1][0];
      this.sensorSelected.latitude = Sensors[num - 1][1];
      this.setChart();
      // const [sensor] = Sensors.filter((_, index) => index == num - 1);
      // this.sensorList = Sensors.filter((_, index) => index == num - 1)
      //   .map(sensor => [sensor[0], sensor[1], [sensor[2], sensor[3]]])
      //   .map((sensor, index) => {
      //     return [
      //       {
      //         lnglat: [sensor[0] - 0.0001, sensor[1]],
      //         value: sensor[2][0],
      //         longitude: sensor[0],
      //         latitude: sensor[1],
      //         values: sensor[2],
      //         label: 'NOx',
      //         point: '测点' + (index + 1)
      //       },
      //       {
      //         lnglat: [sensor[0] + 0.0001, sensor[1]],
      //         value: sensor[2][1],
      //         longitude: sensor[0],
      //         latitude: sensor[1],
      //         values: sensor[2],
      //         label: 'SO2',
      //         point: '测点' + (index + 1)
      //       }
      //     ];
      //   })
      //   .flat();
      // this.sensorSelected = this.sensorList[0];
      // this.setChart();
      // this.layer.setData(this.sensorList, {
      //   lnglat: 'lnglat'
      // });
      this.map.setCenter(new AMap.LngLat(Sensors[num - 1][0], Sensors[num - 1][1]));
      // this.layer.render();
    },
    async trace() {
      this.historyMode = '历史记录';
      this.tableShow = false;
      this.isTrackMode = true;
      const start = new Date(new Date() - 60 * 60 * 1000);
      const end = new Date();
      const { gps_record_list: gpsRecordList, no_record_list: noRecordList, so2_record_list: SO2RecordList } = await getHistoryApi(start, end);
      // if (gpsRecordList.length > 0) {
      //   console.log(this.car);
      //   this.car.show();
      //   this.car.setPosition([gpsRecordList[0].longitude, gpsRecordList[0].latitude]);
      //   this.map.setCenter(new AMap.LngLat(gpsRecordList[0].longitude, gpsRecordList[0].latitude));
      // }
      // const polyline = new AMap.Polyline({
      //   map: this.map,
      //   path: gpsRecordList.map(item => [item.longitude, item.latitude]),
      //   showDir: false,
      //   strokeColor: '#28F', //线颜色
      //   strokeOpacity: 1, //线透明度
      //   strokeWeight: 6, //线宽
      //   strokeStyle: 'solid' //线样式
      // });
      // const passedPolyline = new AMap.Polyline({
      //   map: this.map,
      //   // path: lineArr,
      //   strokeColor: '#AF5', //线颜色
      //   // strokeOpacity: 1,     //线透明度
      //   strokeWeight: 6 //线宽
      //   // strokeStyle: "solid"  //线样式
      // });
      // this.car.on('moving', function (e) {
      //   // passedPolyline.setPath(e.passedPath);
      //   // this.map.setCenter(e.target.getPosition(), true);
      //   console.log(e);
      // });
      // this.map.setFitView();

      // this.car.moveAlong(
      //   gpsRecordList.map(item => [item.longitude, item.latitude]),
      //   {
      //     duration: 10,
      //     autoRotation: true
      //   }
      // );
    },
    checkHour() {},
    checkMore() {
      this.historyMode = '历史记录';
      this.tableShow = true;
      this.dataList = CarData.filter((_, index) => index < 10).map((item, index) => ({
        longitude: item[0],
        latitude: item[1],
        SO2: (300 + Math.random() * 500).toFixed(2),
        NOx: (50 * Math.random()).toFixed(2),
        createdAt: dayjs(new Date(new Date('2021-08-15 15:32').getTime() - 10 * 60 * 1000 * index)).format('YYYY-MM-DD HH:mm')
      }));
    },
    getCarData() {
      this.animate();
      this.carMove();
      this.sensorSelected = {
        values: [0, 0]
      };
      carData.features.forEach((item, index) => {
        setTimeout(() => {
          const pl = new Loca.PrismLayer({
            // loca: this.loca,
            zIndex: 1000,
            opacity: 1,
            visible: false,
            hasSide: true
          });
          pl.setSource(
            new Loca.GeoJSONSource({
              data: { type: 'FeatureCollection', features: this.gasData.filter((it, i) => i == 2 * index || i == 2 * index + 1) }
            })
          );
          pl.setStyle({
            unit: 'meter',
            radius: 2,
            sideNumber: 32,
            sideTopColor: (index, item) => {
              if (index % 2) return '#0041ff';
              else return '#ff8500';
            },
            topColor: (index, item) => {
              if (index % 2) return '#0041ff';
              else return '#ff8500';
            },
            sideBottomColor: (index, item) => {
              if (index % 2) return '#0041ff';
              else return '#ff8500';
            },
            height: (index, item) => {
              const gas = index % 2 ? item.properties.NOx + (Math.random() - 0.5) * 20 : item.properties.SO2 + (Math.random() - 0.5) * 10;
              this.sensorSelected.values[index % 2] = gas.toFixed(2);
              this.sensorSelected.longitude = (index % 2 ? item.coordinates[0] + 0.00002 : item.coordinates[0] - 0.00002).toFixed(6);
              this.sensorSelected.latitude = item.coordinates[1];
              this.setChart();
              this.$forceUpdate();
              // this.map.add(
              //   new AMap.Marker({
              //     anchor: 'bottom-center',
              //     position: [item.coordinates[0], item.coordinates[1], gas * 2],
              //     content:
              //       '<div style="text-align: center; height: 20px; width: 150px; color:#eaeaea; font-size: 14px;">' + (index % 2 ? 'SO2' : 'NOx') + ': ' + gas.toFixed(2) + '</div>'
              //   })
              // );
              return gas;
            }
          });
          this.loca.add(pl);
          // this.dat.addLayer(pl, index);
          pl.show(500);
          pl.addAnimate({
            key: 'height',
            value: [0, 1],
            duration: 500,
            easing: 'Linear'
          });
        }, index * 5000);
      });
    },
    animate() {
      this.map.setZoom(19, true);
      this.map.setPitch(0, true);
      this.map.setCenter([121.528068, 38.881448], true);
      this.loca.animate.start();
      const speed = 1;
      this.loca.viewControl.addAnimates([
        {
          center: {
            value: [121.528092, 38.880949],
            control: [
              [121.528068, 38.881448],
              [121.528092, 38.880949]
            ],
            timing: [0, 0, 1, 1],
            duration: 500 / speed
          },
          pitch: {
            value: 45,
            control: [
              [0, 0],
              [1, 45]
            ],
            timing: [0, 0, 1, 1],
            duration: 500 / speed
          },
          rotation: {
            value: 90,
            control: [
              [0, 0],
              [1, 40]
            ],
            timing: [0, 0, 1, 1],
            duration: 500 / speed
          }
        },
        {
          center: {
            value: [121.527406, 38.880932],
            control: [
              [121.528092, 38.880949],
              [121.527406, 38.880932]
            ],
            timing: [0, 0, 1, 1],
            duration: 4500 / speed
          }
        },
        {
          rotation: {
            value: 0,
            control: [
              [0.4, 90],
              [0.6, 0]
            ],
            timing: [0, 0, 1, 1],
            duration: 1000 / speed
          }
        },
        {
          center: {
            value: [121.527325, 38.883939],
            control: [
              [121.527406, 38.880932],
              [121.527325, 38.883939]
            ],
            timing: [0, 0, 1, 1],
            duration: 29000 / speed
          }
        },
        {
          rotation: {
            value: 90,
            control: [
              [0.4, 0],
              [0.6, 90]
            ],
            timing: [0, 0, 1, 1],
            duration: 1000 / speed
          }
        },
        {
          center: {
            value: [121.525818, 38.883922],
            control: [
              [121.527325, 38.883939],
              [121.525818, 38.883922]
            ],
            timing: [0, 0, 1, 1],
            duration: 9000 / speed
          }
        }
      ]);
    },
    carMove() {
      const CarHourData = carData.features.map(item => item.geometry.coordinates);
      this.marker = new AMap.Marker({
        map: this.map,
        position: [121.528092, 38.880949],
        icon: 'https://a.amap.com/jsapi_demos/static/demo-center-v2/car.png',
        offset: new AMap.Pixel(-26, -13)
        // autoRotation: true,
        // angle: 90
      });
      const polyline = new AMap.Polyline({
        map: this.map,
        path: CarHourData,
        showDir: false,
        strokeColor: '#28F', //线颜色
        strokeOpacity: 0, //线透明度
        strokeWeight: 6, //线宽
        strokeStyle: 'solid' //线样式
      });

      const passedPolyline = new AMap.Polyline({
        map: this.map,
        // path: lineArr,
        strokeColor: '#AF5', //线颜色
        // strokeOpacity: 1,     //线透明度
        strokeWeight: 6 //线宽
        // strokeStyle: "solid"  //线样式
      });
      this.marker.on('moving', function (e) {
        passedPolyline.setPath(e.passedPath);
      });
      // this.map.setFitView();

      this.marker.moveAlong(CarHourData, {
        duration: 5000,
        autoRotation: true
      });
    },
    connectSocket() {
      this.connectGpsSocket();
      this.connectNOxSocket();
      this.connectSO2Socket();
    },
    connectGpsSocket() {
      this.gpsSocket = io(process.env.VUE_APP_BASE_API + '/gps');
      this.gpsSocket.on('connect', _ => {
        this.gpsSocket.emit('joinRoom', res => {
          if (!res) Message.error('节点不存在');
          this.gpsSocket.on('gps', this.gpsHandler);
        });
      });
    },
    connectNOxSocket() {
      this.NOxSocket = io(process.env.VUE_APP_BASE_API + '/no');
      this.NOxSocket.on('connect', _ => {
        this.NOxSocket.emit('joinRoom', res => {
          if (!res) Message.error('节点不存在');
          this.NOxSocket.on('no', this.NOxHandler);
        });
      });
    },
    connectSO2Socket() {
      this.SO2Socket = io(process.env.VUE_APP_BASE_API + '/gps');
      this.SO2Socket.on('connect', _ => {
        this.SO2Socket.emit('joinRoom', res => {
          if (!res) Message.error('节点不存在');
          this.SO2Socket.on('so2', this.SO2Handler);
        });
      });
    },
    checkTrack() {
      this.isTrackMode = true;
      this.connectGpsSocket();
      this.connectNOxSocket();
      this.connectSO2Socket();
    },
    stopTrack() {
      this.isTrackMode = false;
      this.NOLayerList.forEach(item => {
        this.loca.remove(item);
      });
      this.NOLayerList = [];
      this.car.hide();
      this.loca.remove();
      if (this.gpsSocket) {
        this.gpsSocket.on('disconnect', _ => {});
        this.gpsSocket.close();
        this.gpsSocket = undefined;
      }
      if (this.NOxSocket) {
        this.NOxSocket.on('disconnect', _ => {});
        this.NOxSocket.close();
        this.NOxSocket = undefined;
      }
      if (this.SO2Socket) {
        this.SO2Socket.on('disconnect', _ => {});
        this.SO2Socket.close();
        this.SO2Socket = undefined;
      }
    },
    gpsHandler(gpsLongitude, gpsLatitude) {
      let longitude, latitude;
      AMap.convertFrom([gpsLongitude, gpsLatitude], 'gps', (status, result) => {
        longitude = result.locations[0].lng;
        latitude = result.locations[0].lat;
        if (!this.car.getOptions().visible) {
          this.car.setPosition([longitude, latitude]);
          this.car.show();
          this.map.setCenter([longitude, latitude]);
        }
        if (this.trackList.length >= 5) {
          const polyline = new AMap.Polyline({
            map: this.map,
            path: this.trackList,
            showDir: false,
            strokeColor: '#28F', //线颜色
            strokeOpacity: 0, //线透明度
            strokeWeight: 6, //线宽
            strokeStyle: 'solid' //线样式
          });
          const passedPolyline = new AMap.Polyline({
            map: this.map,
            // path: lineArr,
            strokeColor: '#AF5', //线颜色
            // strokeOpacity: 1,     //线透明度
            strokeWeight: 6 //线宽
            // strokeStyle: "solid"  //线样式
          });
          // this.car.on('moving', function (e) {
          //   passedPolyline.setPath(e.passedPath);
          // });
          // this.map.setFitView();

          this.car.moveAlong(JSON.parse(JSON.stringify(this.trackList)), {
            duration: 1000,
            autoRotation: true
          });
          this.trackList = [];
          this.trackList.push([parseFloat(longitude), parseFloat(latitude)]);
        } else this.trackList.push([parseFloat(longitude), parseFloat(latitude)]);
      });
    },
    NOxHandler(NO, NO2, NOx) {
      const NOLayer = new Loca.PrismLayer({
        // loca: this.loca,
        zIndex: 1000,
        opacity: 1,
        visible: false,
        hasSide: true
      });
      const jsonSource = new Loca.GeoJSONSource({
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: this.trackList[this.trackList.length - 1]
              },
              properties: {
                NOx: 40,
                SO2: 5,
                value: NOx / 100
              }
            }
          ]
        }
      });
      NOLayer.setSource(jsonSource);
      NOLayer.setStyle({
        sideTopColor: '#0091ff',
        topColor: '#0091ff',
        sideBottomColor: '#0091ff',
        height: (index, item) => {
          return item.properties.value;
        },
        unit: 'meter',
        radius: 2,
        sideNumber: 32
      });
      this.loca.add(NOLayer);
      this.NOLayerList.push(NOLayer);

      NOLayer.addAnimate({
        key: 'height',
        value: [0, 1],
        duration: 500,
        easing: 'Linear'
      });
      NOLayer.show(500);
      // this.dat.addLayer(NOLayer, NOx / 100);
    },
    setPointData() {
      this.tableShow = false;
      const jsonSource = new Loca.GeoJSONSource({
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [this.pointData.longitude - 0.00002, this.pointData.latitude]
              },
              properties: {
                NOx: this.pointData.NO > 0 ? this.pointData.NO : 0,
                SO2: this.pointData.SO2 > 0 ? this.pointData.SO2 : 0
              }
            }
          ]
        }
      });
      const jsonSource1 = new Loca.GeoJSONSource({
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [this.pointData.longitude + 0.00002, this.pointData.latitude]
              },
              properties: {
                NOx: this.pointData.NO > 0 ? this.pointData.NO : 0,
                SO2: this.pointData.SO2 > 0 ? this.pointData.SO2 : 0
              }
            }
          ]
        }
      });
      if (this.NOxLayer) {
        this.loca.remove(this.NOxLayer);
      }
      if (this.SO2Layer) {
        this.loca.remove(this.SO2Layer);
      }
      this.NOxLayer = new Loca.PrismLayer({
        // loca: this.loca,
        zIndex: 1000,
        opacity: 1,
        visible: false,
        hasSide: true
      });
      this.NOxLayer.setSource(jsonSource);
      this.NOxLayer.setStyle({
        sideTopColor: '#0091ff',
        topColor: '#0091ff',
        sideBottomColor: '#0091ff',
        height: (index, item) => {
          return item.properties.NOx / 100;
        },
        unit: 'meter',
        radius: 2,
        sideNumber: 32
      });
      this.loca.add(this.NOxLayer);
      this.NOxLayer.show(500);
      this.NOxLayer.addAnimate({
        key: 'height',
        value: [0, 1],
        duration: 1000,
        easing: 'Linear'
      });
      this.SO2Layer = new Loca.PrismLayer({
        // loca: this.loca,
        zIndex: 1000,
        opacity: 1,
        visible: false,
        hasSide: true
      });

      this.SO2Layer.setSource(jsonSource1);

      this.SO2Layer.setStyle({
        sideTopColor: '#ff8500',
        topColor: '#ff8500',
        sideBottomColor: '#ff8500',
        height: (index, item) => {
          return item.properties.SO2;
        },
        unit: 'meter',
        radius: 2,
        sideNumber: 32
      });

      this.loca.add(this.SO2Layer);

      this.SO2Layer.show(500);
      this.SO2Layer.addAnimate({
        key: 'height',
        value: [0, 1],
        duration: 1000,
        easing: 'Linear'
      });
      this.sensorSelected = {
        values: []
      };
      this.sensorSelected.values[0] = this.pointData.SO2 > 0 ? this.pointData.SO2 : 0;
      this.sensorSelected.values[1] = this.pointData.NO > 0 ? this.pointData.NO : 0;
      this.sensorSelected.longitude = this.pointData.longitude;
      this.sensorSelected.latitude = this.pointData.latitude;
      this.setChart();
      this.map.setCenter(new AMap.LngLat(this.pointData.longitude, this.pointData.latitude));
    },
    getGps() {
      this.gpsSocket = io(process.env.VUE_APP_BASE_API + '/gps');
      this.gpsSocket.on('connect', _ => {
        this.gpsSocket.emit('joinRoom', res => {
          if (!res) Message.error('节点不存在');
          this.gpsSocket.on('gps', (gpsLongitude, gpsLatitude) => {
            AMap.convertFrom([gpsLongitude, gpsLatitude], 'gps', (status, result) => {
              this.pointData.longitude = result.locations[0].lng;
              this.pointData.latitude = result.locations[0].lat;
              this.gpsSocket.on('disconnect', _ => {});
              this.gpsSocket.close();
              this.gpsSocket = undefined;
            });
          });
          setTimeout(() => {
            if (!(this.pointData.longitude && this.pointData.latitude)) {
              Message.error('未获取到gps数据');
              this.stopTrack();
              this.loading = false;
            }
          }, 2000);
        });
      });
    },
    getNO() {
      this.NOxSocket = io(process.env.VUE_APP_BASE_API + '/no');
      this.NOxSocket.on('connect', _ => {
        this.NOxSocket.emit('joinRoom', res => {
          if (!res) Message.error('节点不存在');
          const timeout = setTimeout(() => {
            if (!this.pointData.NO) {
              Message.error('未获取到NO数据');
              this.stopTrack();
              this.loading = false;
            }
          }, 10000);
          this.NOxSocket.on('no', (NO, NO2, NOx) => {
            this.pointData.NO = NOx;
            this.NOxSocket.on('disconnect', _ => {});
            this.NOxSocket.close();
            this.NOxSocket = undefined;
            clearTimeout(timeout);
          });
        });
      });
    },
    getSO2() {
      this.SO2Socket = io(process.env.VUE_APP_BASE_API + '/so2');
      this.SO2Socket.on('connect', _ => {
        this.SO2Socket.emit('joinRoom', res => {
          if (!res) Message.error('节点不存在');
          const timeout = setTimeout(() => {
            if (!this.pointData.SO2) {
              Message.error('未获取到SO2数据');
              this.stopTrack();
              this.loading = false;
            }
          }, 10000);
          this.NOxSocket.on('no', SO2 => {
            this.pointData.SO2 = SO2;
            this.SO2Socket.on('disconnect', _ => {});
            this.SO2Socket.close();
            this.SO2Socket = undefined;
            clearTimeout(timeout);
          });
        });
      });
    },
    getPointData() {
      this.loading = true;
      this.pointData.NO = null;
      this.pointData.SO2 = null;
      this.getGps();
      this.getNO();
      this.getSO2();
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
  }
};
</script>

<style lang="scss" scoped>
.map-container {
  width: 100vw;
  height: 100vh;
}
.info-box {
  position: fixed;
  z-index: 5000;
  padding: 20px;
  background: #3b3b3b;
  color: #ffffff;
  text-align: left;
}
.chart {
  // width: 400px;
  // height: 300px;
  // position: fixed;
  // top: 50px;
  right: -500px !important;
  transition: linear 0.2s;
  &-info {
    padding: 20px;
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 160px;
    &-item {
      color: #ffffff;
      text-align: center;
      margin-bottom: 10px;
    }
  }
}
.chart-show {
  right: 20px !important;
  transition: linear 0.2s;
}
.table-show {
  right: 20px;
}
.buttons {
  position: fixed;
  bottom: 0;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.chart-info {
  color: red;
}
.data-table {
  padding: 20px;

  ::v-deep .el-table {
    background: transparent;
    tr,
    th {
      background: transparent;
      color: #fff;
    }
  }
  .pagination {
    margin-top: 20px;
    color: #fff;
    ::v-deep .el-pagination__total {
      color: #fff;
    }
    ::v-deep .el-pagination__jump {
      color: #fff;
    }
    ::v-deep .el-pagination__total {
      color: #fff;
    }
  }
  ::v-deep .el-pager {
    .number {
      background: transparent;
      color: #fff;
    }
  }
  ::v-deep .btn-prev {
    background: transparent !important;
    color: #fff !important;
  }
  ::v-deep .btn-next {
    background: transparent !important;
    color: #fff !important;
  }
  ::v-deep .el-input__inner {
    background: transparent;
    color: #fff;
  }
  ::v-deep .el-range-input {
    background: transparent;
    color: #fff;
  }
}

.track-mode {
  // .buttons {
  //   bottom: -60px;
  //   transition: bottom 0.5s linear;
  // }
}

.slide-fade-enter-active {
  transition: all 0.25s linear;
}
.slide-fade-leave-active {
  transition: all 0.25s linear;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(100px);
  opacity: 0;
}
.search-box {
  position: fixed;
  top: 60px;
  left: 60px;
}
</style>

<style>
.amap-icon {
  overflow: visible !important;
}
.amap-sug-result {
  top: 0;
}
</style>
