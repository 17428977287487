<template>
  <div class="data-box"
    v-if="sensorSelected">
    <div class="data-box_row">测点经度: {{ sensorSelected.lng.toFixed(6) }}</div>
    <div class="data-box_row">测点纬度: {{ sensorSelected.lat.toFixed(6) }}</div>
    <div class="data-box_row">NOx浓度: {{ sensorSelected.NOx }}</div>
    <div class="data-box_row">SO2浓度: {{ sensorSelected.SO2 }}</div>
    <div class="data-box_row">PM浓度: {{ sensorSelected.PM }}</div>
  </div>
</template>

<script>
export default {
  props: {
    sensorSelected: {
      type: Object,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped>
.data-box {
  padding: 20px;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &_row {
    color: #ffffff;
    text-align: center;
    margin-bottom: 10px;
  }
}
</style>
