import request from '@/utils/request';

/**
 * 设置参照点
 * @param {object} point1 参照点1
 * @param {object} point2 参照点2
 * @returns
 */
export const setReferencePointApi = (point1, point2) =>
  request.post('detect/reference', {
    point1,
    point2
  });

/**
 * 上传测点数据
 * @param {number} id 测点编号
 * @param {number} latitude 纬度
 * @param {number} longitude 经度
 * @param {number} so2 so2浓度
 * @param {number} no no浓度
 * @param {number} nox nox浓度
 * @returns
 */
export const setPointDataApi = (id, latitude, longitude, so2, no, nox) =>
  request.post('detect/record/' + id, {
    latitude,
    longitude,
    so2,
    no,
    nox
  });

/** 溯源 */
export const traceApi = () => request.post('detect/trace');
